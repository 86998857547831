import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueGtag from "vue-gtag";

loadFonts();

createApp(App)
  .component("EasyDataTable", Vue3EasyDataTable)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueSweetalert2)
  .use(
    VueGtag,
    {
      appName: "Journey Tool",
      pageTrackerScreenviewEnabled: true,
      config: { id: "G-0WSZZ7H1J7" },
    },
    router,
  )
  .mount("#app");
