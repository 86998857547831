import { createStore } from "vuex";

import {
  auth,
  signInWithEmailAndPassword,
  signOut,
} from "../firebaseConfig.js";

export default createStore({
  state: {
    user: {
      loggedIn: false,
      data: null,
    },
    country: "South Africa",
    QuizPageOneChoice: "",
    QuizPageTwoChoice: "",
    QuizPageThreeChoice: "",
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER_DATA(state, data) {
      state.user.data = data;
    },
    SET_COUNTRY(state, country) {
      state.country = country;
    },
    SET_QUIZ_PAGE_ONE_CHOICE(state, QuizPageOneChoice) {
      state.QuizPageOneChoice = QuizPageOneChoice;
    },
    SET_QUIZ_PAGE_TWO_CHOICE(state, QuizPageTwoChoice) {
      state.QuizPageTwoChoice = QuizPageTwoChoice;
    },
    SET_QUIZ_PAGE_THREE_CHOICE(state, QuizPageThreeChoice) {
      state.QuizPageThreeChoice = QuizPageThreeChoice;
    },
  },
  actions: {
    async logIn(context, { email, password }) {
      console.log("logIn User Action:" + email + password);
      const response = await signInWithEmailAndPassword(auth, email, password);
      if (response) {
        console.log("logIn User Action: response was true");
      } else {
        throw new Error("login failed");
      }
    },
    async logOut(context) {
      await signOut(auth);
      context.commit("SET_USER_DATA", null);
    },
    async fetchUser(context, user) {
      console.log("fetchUser Action is running");
      context.commit("SET_LOGGED_IN", user !== null);
      if (user) {
        context.commit("SET_USER_DATA", {
          displayName: user.displayName, // This might be null
          email: user.email,
        });
      } else {
        context.commit("SET_USER_DATA", null);
      }
    },
    setCountry({ commit }, country) {
      commit("SET_COUNTRY", country);
    },
    setQuizPageOneChoice({ commit }, QuizPageOneChoice) {
      commit("SET_QUIZ_PAGE_ONE_CHOICE", QuizPageOneChoice);
    },
    setQuizPageTwoChoice({ commit }, QuizPageTwoChoice) {
      commit("SET_QUIZ_PAGE_TWO_CHOICE", QuizPageTwoChoice);
    },
    setQuizPageThreeChoice({ commit }, QuizPageThreeChoice) {
      commit("SET_QUIZ_PAGE_THREE_CHOICE", QuizPageThreeChoice);
    },
  },
  getters: {
    user: (state) => state.user,
    loggedIn: (state) => state.user.loggedIn,
    country: (state) => state.country,
    QuizPageOneChoice: (state) => state.QuizPageOneChoice,
    QuizPageTwoChoice: (state) => state.QuizPageTwoChoice,
    QuizPageThreeChoice: (state) => state.QuizPageThreeChoice,
  },
  modules: {},
});
