// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  getFirestore,
  collection,
  doc,
  getDocs,
  getDoc,
  addDoc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBkvr1VOSuyAQciPsY-RT_9Inkio7ewPv0",
  authDomain: "witsrhi-journeytool-v2.firebaseapp.com",
  projectId: "witsrhi-journeytool-v2",
  storageBucket: "witsrhi-journeytool-v2.appspot.com",
  messagingSenderId: "1042536088052",
  appId: "1:1042536088052:web:da04c962ef72725faa79a1",
  measurementId: "G-9MNX9FNC0M",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth();
const db = getFirestore();
export {
  app,
  auth,
  signInWithEmailAndPassword,
  signOut,
  db,
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  collection,
  doc,
  getDocs,
  getDoc,
  addDoc,
  deleteDoc,
  setDoc,
};
