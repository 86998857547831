// Styles
import "@mdi/font/css/materialdesignicons.css";
// import "vuetify/styles";
import "../main.scss";

// Vuetify
import { createVuetify } from "vuetify";

const myCustomLightTheme = {
  dark: false,
  colors: {
    customfuschia: "#b43688",
    custompoppy: "#e24149",
    customgrey: "#efede4",
    customyellow: "#e3d80d",
  },
};

export default createVuetify({
  theme: {
    defaultTheme: "myCustomLightTheme",
    themes: {
      myCustomLightTheme,
    },
  },
  defaults: {
    global: {
      ripple: true,
    },
    VCard: {
      elevation: 0,
      rounded: 0,
    },
  },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
