<template>
  <v-card>
    <v-row>
      <v-col sm="12" lg="4">
        <v-card-title>{{
          editing && editingItem
            ? `Editing ${editingItem.name}`
            : "Create Method"
        }}</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="name"
              :counter="40"
              :rules="[
                (v) => !!v || 'Name is required',
                (v) =>
                  (v && v.length <= 40) ||
                  'Name must be less than 40 characters',
              ]"
              label="Name"
              required
            ></v-text-field>

            <v-textarea
              v-model="blurb"
              :rules="[(v) => !!v || 'Blurb is required']"
              label="Blurb"
              required
            ></v-textarea>

            <v-textarea
              v-model="resultText"
              :rules="[(v) => !!v || 'Result text is required']"
              label="Result text"
              required
            ></v-textarea>

            <v-text-field
              v-model="whatIsIt"
              :rules="[(v) => !!v || 'What is it is required']"
              label="What is it?"
              required
            ></v-text-field>

            <v-text-field
              v-model="howDoesItWork"
              :rules="[(v) => !!v || 'How does it work is required']"
              label="How does it work?"
              required
            ></v-text-field>

            <v-text-field
              v-model="howIsItUsed"
              :rules="[(v) => !!v || 'How is it used is required']"
              label="How is it used?"
              required
            ></v-text-field>

            <v-text-field
              v-model="howWellDoesItWork"
              :rules="[(v) => !!v || 'How well does it work is required']"
              label="How well does it work?"
              required
            ></v-text-field>

            <v-text-field
              v-model="whatAreTheSideEffect"
              :rules="[(v) => !!v || 'What are the side effects is required']"
              label="What are the side effects?"
              required
            ></v-text-field>

            <v-card
              color="purple-lighten-5"
              theme="dark"
              class="mb-5 pt-5 px-5"
            >
              <v-row>
                <v-col cols="12" class="d-flex">
                  <v-text-field
                    v-model="newConsideration"
                    label="Consideration"
                  >
                  </v-text-field>

                  <v-text-field
                    v-model="newConsiderationPosition"
                    label="Consideration Position"
                    class="ml-2"
                  >
                  </v-text-field>

                  <v-btn
                    icon="mdi-plus"
                    color="info"
                    @click="addConsideration"
                    class="ml-2 mt-2"
                  >
                  </v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-card
                    v-for="(consideration, index) in considerations"
                    :key="index"
                    class="pa-2 mt-n3 mb-2"
                    color="blue-lighten-5"
                  >
                    {{ `${consideration.position}: ${consideration.text}` }}
                    <v-icon
                      icon="mdi-delete"
                      @click="deleteConsideration(index)"
                    ></v-icon>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              color="purple-lighten-5"
              theme="dark"
              class="mb-5 pt-5 px-5"
            >
              <v-row>
                <v-col cols="12" class="d-flex">
                  <v-textarea v-model="newFaqQuestion" label="FAQ Question">
                  </v-textarea>
                  <v-textarea
                    v-model="newFaqAnswer"
                    label="FAQ Answer"
                    class="ml-2"
                  >
                  </v-textarea>

                  <v-text-field
                    v-model="newFaqPosition"
                    label="FAQ Position"
                    class="ml-2"
                  >
                  </v-text-field>

                  <v-btn
                    icon="mdi-plus"
                    color="info"
                    @click="addFaq"
                    class="ml-2 mt-2"
                  >
                  </v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <v-card
                    v-for="(faq, index) in faqs"
                    :key="index"
                    class="pa-2 mt-n3 mb-2"
                    color="blue-lighten-5"
                  >
                    {{ `${faq.position}: question: ${faq.question}` }}
                    <br />
                    {{ `answer: ${faq.answer}` }}
                    <v-icon
                      icon="mdi-delete"
                      @click="deleteFaq(index)"
                    ></v-icon>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>

            <v-sheet class="d-flex">
              <v-file-input
                accept="image/*"
                label="Image"
                show-size
                @change="onFilePicked"
                v-model="imagePicker"
                required
                :rules="[(v) => !!v || 'An image is required']"
              ></v-file-input>
              <v-img
                v-if="imageUrl"
                class="ml-2"
                :src="imageUrl"
                :aspect-ratio="1"
                height="60"
                width="60"
                fit
              />
            </v-sheet>

            <v-text-field
              v-model.number="position"
              :rules="[
                (v) =>
                  Number.isInteger(Number(v)) ||
                  'The value must be an integer number',
              ]"
              label="Position"
              required
            ></v-text-field>

            <v-color-picker v-model="color"></v-color-picker>

            <v-switch
              class="mt-5"
              v-model="retainMethod"
              color="green"
              :label="`Retain Method if Positive`"
            ></v-switch>

            <v-switch
              class="mt-5"
              v-model="removeFromDetailsPage"
              color="green"
              :label="`Remove Method from Details Page`"
            ></v-switch>

            <v-select
              v-model="applicableCountries"
              :items="countries"
              :rules="[
                (v) => !!v || 'One or more countries is required',
                (v) =>
                  (v && v.length > 0) ||
                  'You need to select at least 1 country',
              ]"
              label="Applicable Countries"
              required
              multiple
            ></v-select>

            <v-btn color="info" class="mr-4" @click="validate">
              Validate
            </v-btn>

            <v-btn color="error" class="mr-4" @click="reset">
              Reset Form
            </v-btn>

            <v-btn color="success" class="mr-4" @click="save"> Save </v-btn>
          </v-form>
        </v-card-text>
      </v-col>
      <v-col v-if="allMethods && allMethods.length > 0" sm="12" lg="8">
        <v-card-title>Methods</v-card-title>
        <v-card-text>
          <EasyDataTable
            :headers="headers"
            :items="allMethods"
            :sort-by="'position'"
          >
            <template #item-imageUrl="{ imageUrl }">
              <v-img :src="imageUrl" :aspect-ratio="1" width="60" />
            </template>
            <template #item-blurb="{ blurb }">
              <span v-html="blurb"></span>
            </template>
            <template #item-resultText="{ resultText }">
              <span v-html="resultText"></span>
            </template>
            <template #item-color="{ color }">
              <span
                :style="{
                  backgroundColor: color ? color : '#000000',
                  color: '#FFFFFF',
                  width: '20px',
                  height: '20px',
                }"
                class="pa-5"
                >{{ color ? color : "#000000" }}</span
              >
            </template>
            <template #item-operation="item">
              <div>
                <v-icon icon="mdi-pencil" @click="editItem(item)"></v-icon>
                <v-icon icon="mdi-delete" @click="deleteItem(item)"></v-icon>
              </div>
            </template>
          </EasyDataTable>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import MethodDataService from "../services/MethodDataService";

export default {
  name: "MethodAdmin",
  data() {
    return {
      valid: true,
      editing: false,
      editingItem: "",
      allMethods: [],
      headers: [
        { text: "Name", value: "name" },
        { text: "Image", value: "imageUrl", sortable: false },
        { text: "Blurb", value: "blurb", sortable: false },
        { text: "Result Text", value: "resultText", sortable: false },
        { text: "Color", value: "color", sortable: false },
        { text: "Position", value: "position", sortable: false },
        { text: "Countries", value: "applicableCountries", sortable: false },
        // { text: "ID", value: "id", sortable: false },
        { text: "Operation", value: "operation" },
      ],
      name: "",
      blurb: "",
      resultText: "",
      whatIsIt: "",
      howDoesItWork: "",
      howIsItUsed: "",
      howWellDoesItWork: "",
      whatAreTheSideEffect: "",
      considerations: [],
      newConsideration: "",
      newConsiderationPosition: 0,
      newFaqQuestion: "",
      newFaqAnswer: "",
      newFaqPosition: 0,
      faqs: [],
      imageUrl: "",
      imageFile: "",
      imagePicker: [],
      position: 0,
      color: "#000000",
      retainMethod: false,
      removeFromDetailsPage: false,
      applicableCountries: ["South Africa"],
      countries: ["South Africa", "Kenya", "Uganda", "Tanzania"],
    };
  },
  async created() {
    this.allMethods = await MethodDataService.getAll();
  },
  methods: {
    async validate() {
      const { valid } = await this.$refs.form.validate();
      if (valid) alert("Form is valid");
    },
    reset() {
      this.editing = false;
      this.editingItem = "";
      this.name = "";
      this.blurb = "";
      this.resultText = "";
      this.whatIsIt = "";
      this.howDoesItWork = "";
      this.howIsItUsed = "";
      this.howWellDoesItWork = "";
      this.whatAreTheSideEffect = "";
      this.considerations = [];
      this.faqs = [];
      this.imageUrl = "";
      this.imageFile = "";
      this.imagePicker = [];
      this.position = 0;
      this.color = "#000000";
      this.retainMethod = false;
      this.removeFromDetailsPage = false;
      this.applicableCountries = ["South Africa"];
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    addConsideration() {
      console.log(this.newConsideration);
      this.considerations.push({
        text: this.newConsideration,
        position: this.newConsiderationPosition,
      });
      this.newConsideration = "";
      this.newConsiderationPosition = 0;
    },
    deleteConsideration(index) {
      this.considerations.splice(index, 1);
    },
    addFaq() {
      this.faqs.push({
        question: this.newFaqQuestion,
        answer: this.newFaqAnswer,
        position: this.newFaqPosition,
      });
      this.newFaqQuestion = "";
      this.newFaqAnswer = "";
      this.newFaqPosition = 0;
    },
    deleteFaq(index) {
      this.faqs.splice(index, 1);
    },
    async onFilePicked(e) {
      if (!e.target.files.length) return;
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageFile = files[0];
        console.log(this.imageFile);
        this.imageUrl = await MethodDataService.uploadImage(this.imageFile);
        console.log(this.imageUrl);
      } else {
        this.imageFile = "";
      }
    },
    async save() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        if (this.color.endsWith("NAN")) {
          this.color = this.color.substring(0, this.color.length - 3);
        }
        const method = {
          name: this.name,
          blurb: this.blurb,
          resultText: this.resultText,
          whatIsIt: this.whatIsIt,
          howDoesItWork: this.howDoesItWork,
          howIsItUsed: this.howIsItUsed,
          howWellDoesItWork: this.howWellDoesItWork,
          whatAreTheSideEffect: this.whatAreTheSideEffect,
          considerations: this.considerations,
          faqs: this.faqs,
          imageUrl: this.imageUrl,
          position: this.position,
          color: this.color,
          retainMethod: this.retainMethod,
          removeFromDetailsPage: this.removeFromDetailsPage,
          applicableCountries: this.applicableCountries,
        };
        console.log(method);
        if (this.editing && this.editingItem && this.editingItem.id) {
          var updateResponse = await MethodDataService.update(
            this.editingItem.id,
            method,
          );
          if (updateResponse) {
            this.$swal("Method updated successfully");
          } else {
            this.$swal("Error updating method", "", "error");
          }
        } else {
          var createResponse = await MethodDataService.create(method);
          console.log("createResponse:", createResponse);
          if (createResponse) {
            this.$swal("Method created successfully");
          }
        }
        this.reset();
        this.allMethods = await MethodDataService.getAll();
      }
    },
    editItem(item) {
      this.editing = true;
      this.editingItem = item;
      this.name = item.name || "";
      this.blurb = item.blurb || "";
      this.resultText = item.resultText || "";
      this.whatIsIt = item.whatIsIt || "";
      this.howDoesItWork = item.howDoesItWork || "";
      this.howIsItUsed = item.howIsItUsed || "";
      this.howWellDoesItWork = item.howWellDoesItWork || "";
      this.whatAreTheSideEffect = item.whatAreTheSideEffect || "";
      this.considerations = item.considerations || [];
      this.faqs = item.faqs || [];
      this.imageUrl = item.imageUrl || "";
      this.position = item.position || 0;
      this.color = item.color || "#000000";
      this.retainMethod = item.retainMethod || false;
      this.removeFromDetailsPage = item.removeFromDetailsPage || false;
      this.applicableCountries = item.applicableCountries || [];
    },
    async deleteItem(item) {
      console.log(item);
      this.$swal({
        title: "Are you sure you want to delete this item?",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var deleteResponse = await MethodDataService.delete(item.id);
          if (deleteResponse) {
            this.$swal("Method deleted successfully");
            this.allMethods = await MethodDataService.getAll();
          } else {
            this.$swal("Error deleting method", "", "error");
          }
        } else if (result.isDismissed) {
          this.$swal("Cancelled", "", "info");
        }
      });
    },
  },
};
</script>

<style scoped></style>
