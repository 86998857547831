import {
  db,
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  collection,
  doc,
  getDocs,
  getDoc,
  addDoc,
  deleteDoc,
  setDoc,
} from "../firebaseConfig.js";

const methodsCollectionRef = collection(db, "methods");

class MethodDataService {
  async getAll() {
    const docsSnapshot = await getDocs(methodsCollectionRef);
    return docsSnapshot.docs.map((docRef) => {
      return { ...docRef.data(), id: docRef.id };
    });
  }

  async get(id) {
    var docRef = doc(db, "methods", id);
    var docSnapshot = await getDoc(docRef);
    return { ...docSnapshot.data(), id: docSnapshot.id };
  }

  async create(method) {
    var docRef = await addDoc(methodsCollectionRef, method);
    return { ...method, id: docRef.id };
  }

  async update(id, value) {
    try {
      console.log("value", value);
      await setDoc(doc(db, "methods", id), value, { merge: true });
      return true;
    } catch (error) {
      console.log("Error updating document: ", error);
      return false;
    }
  }

  async delete(id) {
    try {
      await deleteDoc(doc(db, "methods", id));
      return true;
    } catch (error) {
      console.log("Error deleting document: ", error);
      return false;
    }
  }

  async uploadImage(imageFile) {
    var storageRef = ref(getStorage(), "methods/" + imageFile.name);
    const metadata = {
      contentType: imageFile.type,
    };
    await uploadBytes(storageRef, imageFile, metadata);
    return await getDownloadURL(storageRef);
  }
}

export default new MethodDataService();
