<template>
  <v-app-bar
    color="rgba(37,64,143,1)"
    shrink-on-scroll
    scroll-target="#scrolling-techniques-3"
    class="text-white"
  >
    <v-app-bar-title>Admin </v-app-bar-title>
    <v-spacer></v-spacer>
    <v-btn v-if="loggedIn" color="info" @click="logOut"> Logout </v-btn>
    <template v-slot:extension>
      <v-tabs align-with-title v-model="tabs">
        <v-tab value="login">Login</v-tab>
        <v-tab v-if="loggedIn" value="methods">Methods</v-tab>
        <v-tab v-if="loggedIn" value="questions">Questions</v-tab>
      </v-tabs>
    </template>
  </v-app-bar>
  <v-window v-model="tabs" id="scrolling-techniques-3" class="overflow-y-auto">
    <v-window-item value="login">
      <login-admin></login-admin>
    </v-window-item>
    <v-window-item v-if="loggedIn" value="methods">
      <method-admin></method-admin>
    </v-window-item>
    <v-window-item v-if="loggedIn" value="questions">
      <question-admin></question-admin>
    </v-window-item>
  </v-window>
</template>

<script>
import LoginAdmin from "./LoginAdmin.vue";
import MethodAdmin from "./MethodAdmin.vue";
import QuestionAdmin from "./QuestionAdmin.vue";
import { mapActions, mapGetters } from "vuex";
import { auth } from "../firebaseConfig.js";
export default {
  name: "AdminDashboard",
  components: { LoginAdmin, MethodAdmin, QuestionAdmin },
  data() {
    return {
      tabs: "login",
    };
  },
  async created() {
    const self = this;
    auth.onAuthStateChanged((user) => {
      self.fetchUser(user);
    });
  },
  computed: {
    ...mapGetters(["user", "loggedIn"]),
  },
  methods: {
    ...mapActions(["logIn", "logOut", "fetchUser"]),
  },
};
</script>

<style scoped></style>
