<template>
  <v-container fluid class="home">
    <header-bar></header-bar>
    <v-row class="mt-1">
      <v-col cols="12" align-center lg="12" sm="12">
        <v-card class="custom-background-color home-card mx-n4 pa-10">
          <v-card-text class="text-black d-flex justify-center flex-column">
            <div class="text-h5 font-weight-bold text-center">
              Do you want an HIV prevention method that works for you?
            </div>
            <v-btn
              class="rounded-pill text-white mt-5"
              color="#B43688"
              size="x-large"
              @click="navigateNext"
            >
              TAKE THE QUIZ
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HeaderBar from "../components/HeaderBar.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "HomePage",
  components: {
    HeaderBar,
  },
  async created() {
    console.log(this.$route.query);
    if (
      this.$route.query &&
      this.$route.query.country &&
      ["South Africa", "Kenya"].includes(this.$route.query.country)
    ) {
      await this.$store.dispatch("setCountry", this.$route.query.country);
    } else {
      await this.$store.dispatch("setCountry", "South Africa");
    }
    console.log("You are using the country: " + this.country);
  },
  computed: {
    ...mapGetters(["country"]),
  },
  methods: {
    ...mapActions(["setCountry"]),
    navigateNext() {
      this.$router.push("/QuizPageOne");
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-image: url("../assets/home_background.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  min-height: 100vh;
}

.home-card {
  top: 50%;
}
</style>
