<template>
  <v-container>
    <header-bar></header-bar>
    <v-row class="my-4 mx-1">
      <v-col cols="12" align-center lg="12" sm="12">
        <div
          class="d-flex flex-row align-center justify-center mb-8 custom-grey font-weight-bold"
        >
          <span>Step 1</span>
          <span class="line"></span>
          <span class="custom-fuschia">Step 2</span>
          <span class="line"></span>
          <span>Step 3</span>
        </div>
        <div class="custom-fuschia text-h5 font-weight-bold mb-3">
          <div>Things I like and want...</div>
          or don't want.
        </div>
        <div>
          <span class="font-weight-bold">Click the ONE option </span> below that
          best describes what you need. Then click
          <span class="font-weight-bold">next.</span>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-2" no-gutters v-if="choices && choices.length > 0">
      <v-col
        v-for="achoice in choices"
        cols="6"
        lg="6"
        md="6"
        sm="6"
        :key="achoice.id"
        class="d-flex flex-row"
      >
        <v-card
          class="pa-6 ma-2 text-black text-body-2 font-weight-medium rounded-0"
          style="width: 100%"
          :color="achoice.id === choice ? '#F491AF' : '#E3D80D'"
          :elevation="achoice.id === choice ? 5 : 0"
          @click="setChoice(achoice.id)"
        >
          <div class="">
            {{ achoice.text }}
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="text-center">
        <v-progress-circular
          indeterminate="true"
          color="customfuschia"
        ></v-progress-circular>
      </v-col>
    </v-row>

    <v-row class="mt-2 mx-1">
      <v-col
        cols="12"
        align-center
        lg="12"
        sm="12"
        class="my-1 ml d-flex flex-row align-center"
      >
        <v-img
          width="40"
          max-width="40"
          src="@/assets/icons/asterisk.png"
          cover
          class="mr-2"
        ></v-img>
        <div class="custom-fuschia">
          <span
            class="font-weight-black text-decoration-underline"
            @click="goToPepPage"
            >Click here for PEP:</span
          >
          I may have been exposed to HIV in the past three days (72 hours)
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col class="d-flex flex-row align-center justify-space-around">
        <v-btn
          class="text-white rounded-pill"
          size="large"
          @click="goQuizPageOne"
          color="customfuschia"
        >
          BACK
        </v-btn>
        <v-btn
          :class="['rounded-pill', choice === null ? '' : 'text-customyellow']"
          size="large"
          @click="goQuizPageThree"
          :disabled="choice === null"
          color="customfuschia"
        >
          NEXT
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import QuestionDataService from "../services/QuestionDataService";
import HeaderBar from "../components/HeaderBar.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "QuizPageTwo",
  components: { HeaderBar },
  data: () => ({
    choices: [],
    choice: null,
  }),
  async created() {
    this.allQuestions = await QuestionDataService.getAll();
    this.choices = this.allQuestions.map((question) => {
      return {
        id: question.id,
        text: question.text,
        position: question.position,
      };
    });
    this.choices.sort((a, b) => (a.position < b.position ? -1 : 1));
    this.choices = this.choices.slice(4, 8);
    if (this.QuizPageTwoChoice) {
      this.choice = this.QuizPageTwoChoice;
    }
  },
  computed: {
    ...mapGetters(["QuizPageTwoChoice"]),
  },
  methods: {
    ...mapActions(["setQuizPageTwoChoice"]),
    setChoice(id) {
      if (id === this.choice) this.choice = null;
      else this.choice = id;
      this.setQuizPageTwoChoice(this.choice);
    },
    goQuizPageOne() {
      this.$router.push({
        name: "QuizPageOne",
      });
    },
    goQuizPageThree() {
      console.log("Routing to Yes Page");
      if (this.choice != null) {
        const choiceText = this.choices.find((c) => c.id === this.choice).text;
        this.$gtag.event("Quiz Page Two Choice", {
          event_category: "quiz_page_two_choice",
          event_label: "quiz_page_two_choice",
          value: choiceText,
        });
        this.$router.push({
          name: "QuizPageThree",
        });
      } else {
        alert("You must select a choice to proceed.");
      }
    },
    goToPepPage() {
      this.$router.push({
        name: "Method Details",
        params: { choice: "zgUFO3cuvK4yrp7aUiTD" },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
