<template>
  <v-container>
    <header-bar></header-bar>

    <v-row class="my-4 mx-1">
      <v-col cols="12" align-center lg="12" sm="12">
        <div class="custom-fuschia text-h5 font-weight-bold mb-2">
          STI Management
        </div>
      </v-col>
    </v-row>

    <v-row class="mx-1">
      <v-col cols="12" align-center lg="12" sm="12">
        <v-expansion-panels v-if="activeMethod">
          <v-expansion-panel
            class="mb-2"
            elevation="0"
            :style="{ border: `2px solid ${primaryColor}` }"
          >
            <v-expansion-panel-title>
              <template v-slot:default="{ expanded }">
                <v-fade-transition leave-absolute>
                  <div
                    class="font-weight-bold"
                    :style="{ color: expanded ? primaryColor : '#000000' }"
                  >
                    What is it?
                  </div>
                </v-fade-transition>
              </template>
              <template v-slot:actions="{ expanded }">
                <v-icon
                  :color="expanded ? '#000000' : primaryColor"
                  :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                ></v-icon>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div v-html="activeMethod.whatIsIt"></div>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel
            class="mb-2"
            elevation="0"
            :style="{ border: `2px solid ${primaryColor}` }"
          >
            <v-expansion-panel-title>
              <template v-slot:default="{ expanded }">
                <v-fade-transition leave-absolute>
                  <div
                    class="font-weight-bold"
                    :style="{ color: expanded ? primaryColor : '#000000' }"
                  >
                    How does it work?
                  </div>
                </v-fade-transition>
              </template>
              <template v-slot:actions="{ expanded }">
                <v-icon
                  :color="expanded ? '#000000' : primaryColor"
                  :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                ></v-icon>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div v-html="activeMethod.howDoesItWork"></div>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel
            class="mb-2"
            elevation="0"
            :style="{ border: `2px solid ${primaryColor}` }"
          >
            <v-expansion-panel-title>
              <template v-slot:default="{ expanded }">
                <v-fade-transition leave-absolute>
                  <div
                    class="font-weight-bold"
                    :style="{ color: expanded ? primaryColor : '#000000' }"
                  >
                    How is it used?
                  </div>
                </v-fade-transition>
              </template>
              <template v-slot:actions="{ expanded }">
                <v-icon
                  :color="expanded ? '#000000' : primaryColor"
                  :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                ></v-icon>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div v-html="activeMethod.howIsItUsed"></div>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel
            class="mb-2"
            elevation="0"
            :style="{ border: `2px solid ${primaryColor}` }"
          >
            <v-expansion-panel-title>
              <template v-slot:default="{ expanded }">
                <v-fade-transition leave-absolute>
                  <div
                    class="font-weight-bold"
                    :style="{ color: expanded ? primaryColor : '#000000' }"
                  >
                    How well does it work?
                  </div>
                </v-fade-transition>
              </template>
              <template v-slot:actions="{ expanded }">
                <v-icon
                  :color="expanded ? '#000000' : primaryColor"
                  :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                ></v-icon>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div v-html="activeMethod.howWellDoesItWork"></div>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel
            class="mb-2"
            elevation="0"
            :style="{ border: `2px solid ${primaryColor}` }"
          >
            <v-expansion-panel-title>
              <template v-slot:default="{ expanded }">
                <v-fade-transition leave-absolute>
                  <div
                    class="font-weight-bold"
                    :style="{ color: expanded ? primaryColor : '#000000' }"
                  >
                    What are the side effects?
                  </div>
                </v-fade-transition>
              </template>
              <template v-slot:actions="{ expanded }">
                <v-icon
                  :color="expanded ? '#000000' : primaryColor"
                  :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                ></v-icon>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div v-html="activeMethod.whatAreTheSideEffect"></div>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel
            class="mb-2"
            elevation="0"
            :style="{ border: `2px solid ${primaryColor}` }"
          >
            <v-expansion-panel-title>
              <template v-slot:default="{ expanded }">
                <v-fade-transition leave-absolute>
                  <div
                    class="font-weight-bold"
                    :style="{ color: expanded ? primaryColor : '#000000' }"
                  >
                    Some considerations when choosing this option.
                  </div>
                </v-fade-transition>
              </template>
              <template v-slot:actions="{ expanded }">
                <v-icon
                  :color="expanded ? '#000000' : primaryColor"
                  :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                ></v-icon>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <ul id="considerations" class="pl-4">
                <li
                  v-for="(item, index) in activeMethod.considerations"
                  :key="index"
                  class="mb-2"
                >
                  <div v-html="item.text"></div>
                </li>
              </ul>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel
            class="mb-2"
            elevation="0"
            :style="{ border: `2px solid ${primaryColor}` }"
          >
            <v-expansion-panel-title>
              <template v-slot:default="{ expanded }">
                <v-fade-transition leave-absolute>
                  <div
                    class="font-weight-bold"
                    :style="{ color: expanded ? primaryColor : '#000000' }"
                  >
                    Frequently Asked Questions.
                  </div>
                </v-fade-transition>
              </template>
              <template v-slot:actions="{ expanded }">
                <v-icon
                  :color="expanded ? '#000000' : primaryColor"
                  :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                ></v-icon>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text style="width: 100%" class="faqpanel">
              <div
                v-for="(item, index) in activeMethod.faqs"
                :key="index"
                :class="{ even: index % 2, odd: !(index % 2) }"
              >
                <div class="px-5 pt-5">
                  <span
                    class="font-weight-bold"
                    :style="{ color: primaryColor }"
                    >Q: </span
                  ><span class="font-weight-bold">{{ item.question }}</span>
                </div>
                <div class="px-5 pb-5">
                  <span
                    class="font-weight-bold"
                    :style="{ color: primaryColor }"
                    >A: </span
                  ><span>{{ item.answer }}</span>
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row class="my-4 mx-1">
      <v-col cols="12" align-center lg="12" sm="12">
        <div
          class="text-h6 font-weight-bold mb-2"
          :style="{ color: primaryColor }"
        >
          More about STIs
        </div>
        <div>
          <p>
            An STI is caused by bacteria, viruses, or parasites. You do not need
            to have lots of sexual partners to get an infection. They are
            common; a lot of people have them or have had them in the past. In
            2017, among adults, 6% of women and 4% of men had gonorrhea, while
            15% of women and 6% of men had chlamydia.
          </p>
          <br />
          <p>
            STIs are passed through sexual contact. You can become infected if
            you have condomless vaginal, anal, or oral sex; through genital
            contact; or share sex toys. Some STIs are carried in body fluids
            like semen (cum), vaginal fluids, and blood, while others can be
            passed from skin-to-skin contact of an infected area on the body.
            Without treatment, STIs can lead to serious health problems,
            including difficulty having children, cervical cancer, or infections
            that spread to other parts of your body.
          </p>
          <br />
          <p>
            There are many types of STIs, but they are not always easy to detect
            because you may be asymptomatic (not showing any symptoms). You can
            also have more than one at a time. Using condoms can help you avoid
            contact with bodily fluids and some types of skin-to-skin contact
            during sex. So, when you do not use condoms, your chance of getting
            an STI increases exponentially. Condoms are the only way to protect
            against all STIs, including HIV.
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row class="my-4 mx-1">
      <v-col cols="12" align-center lg="12" sm="12">
        <div
          class="text-h6 font-weight-bold mb-2"
          :style="{ color: primaryColor }"
        >
          Worried you might have an STI?
        </div>
        <div>
          <p class="font-weight-bold">If you have an STI, it may cause:</p>
          <div class="mx-5">
            <ul>
              <li>A discharge from your penis or vagina</li>
              <li>
                Burning, itching, or redness of the skin on and around your
                genitals
              </li>
              <li>A sore on your genitals</li>
              <li>A growth on your genitals</li>
              <li>Pain in the bottom half of your stomach</li>
            </ul>
          </div>
          <br />
          <p>
            This table lists the symptoms and physical signs caused by different
            types of STIs and other conditions. It is important to go to a
            health care facility if you are experiencing any of the symptoms.
            The health care provider will examine you and provide treatment.
          </p>
          <br />
          <table
            :style="{
              border: `1px solid ${primaryColor}`,
            }"
          >
            <tr :style="{ backgroundColor: primaryColor }">
              <th>Symptoms</th>
              <th>STI</th>
            </tr>
            <tr
              v-for="(item, index) in whatsWrong"
              :key="index"
              :class="{ even: index % 2, odd: !(index % 2) }"
            >
              <td class="pa-2">
                <span class="font-weight-bold">{{ item.question }}</span>
              </td>
              <td class="pa-2">
                <ul class="ml-4">
                  <li v-for="(answer, ansIndex) in item.answer" :key="ansIndex">
                    {{ answer }}
                  </li>
                </ul>
              </td>
            </tr>
          </table>
        </div>
      </v-col>
    </v-row>

    <v-row class="my-4 mx-1">
      <v-col cols="12" align-center lg="12" sm="12">
        <div
          class="text-h6 font-weight-bold mb-2"
          :style="{ color: primaryColor }"
        >
          STI Treatment
        </div>
        <div>
          <p>
            If you have an STI, you will need treatment. The treatment will
            depend on the type of STI; it might be antibiotics or medicines
            called antivirals that fight viruses.
          </p>
          <br />
          <p>
            Treatment can cure your infection or prevent it from getting worse.
            It can also reduce the chance of you spreading the infection to
            others.
          </p>
          <br />
          <p>
            Once you start antibiotic treatment, it is important you finish the
            complete course.
          </p>
          <br />
          <p>
            It is also important to abstain from sex until seven days after you
            have completed antibiotic treatment and any sores have healed. If
            your symptoms do not get better after the antibiotics or get better
            and then worse again, go back to your health care facility – it is
            possible the antibiotics did not work or you have been re-infected.
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row class="my-4 mx-1">
      <v-col cols="12" align-center lg="12" sm="12">
        <div
          class="text-h6 font-weight-bold mb-2"
          :style="{ color: primaryColor }"
        >
          STI and non-STI causes of genital discharge
        </div>
        <div>
          <p>
            Genital discharge is a usual experience for most girls and women,
            and is normal if it is a clear or white liquid that comes out of
            your vagina from time to time during your monthly cycle. It may feel
            a bit wet and sticky, but should not have an unpleasant smell.
          </p>
          <br />
          <p>
            If your discharge has recently changed, smells bad, is brown or
            creamy in colour, or you have itching or sores around your vagina or
            penis, you may have an infection and need to seek medical attention.
          </p>
          <br />
          <p class="font-weight-bold">What STIs cause a discharge?</p>
          <div class="mx-5">
            <ul>
              <li>Chlamydia</li>
              <li>Gonorrhoea</li>
              <li>Trichomoniasis</li>
            </ul>
          </div>
          <br />
          <p class="font-weight-bold">Is a discharge always an STI?</p>
          <p>
            No. Sometimes a discharge can be caused by something that is not
            sexually transmitted or related to an STI.
          </p>
          <br />
          <p class="font-weight-bold">
            Is genital itching or burning always an STI?
          </p>
          <p>
            Some conditions that are not sexually transmitted can cause genital
            itching or burning. These include:
          </p>
          <div class="mx-5">
            <ul>
              <li>Urinary tract infection (UTI)</li>
              <li>Thrush</li>
            </ul>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row class="my-4 mx-1" v-for="(sti, stiindex) in stis" :key="stiindex">
      <v-col cols="12" align-center lg="12" sm="12">
        <div
          class="text-h6 font-weight-bold mb-2"
          :style="{ color: primaryColor }"
        >
          {{ sti.heading }}
        </div>
        <div>
          <v-expansion-panels>
            <v-expansion-panel
              v-if="sti.whatIsIt"
              class="mb-2"
              elevation="0"
              :style="{ border: `2px solid ${primaryColor}` }"
            >
              <v-expansion-panel-title>
                <template v-slot:default="{ expanded }">
                  <v-fade-transition leave-absolute>
                    <div
                      class="font-weight-bold"
                      :style="{ color: expanded ? primaryColor : '#000000' }"
                    >
                      What is {{ sti.name }}?
                    </div>
                  </v-fade-transition>
                </template>
                <template v-slot:actions="{ expanded }">
                  <v-icon
                    :color="expanded ? '#000000' : primaryColor"
                    :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                  ></v-icon>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <div v-html="sti.whatIsIt"></div>
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel
              v-if="sti.howCanIGetIt"
              class="mb-2"
              elevation="0"
              :style="{ border: `2px solid ${primaryColor}` }"
            >
              <v-expansion-panel-title>
                <template v-slot:default="{ expanded }">
                  <v-fade-transition leave-absolute>
                    <div
                      class="font-weight-bold"
                      :style="{ color: expanded ? primaryColor : '#000000' }"
                    >
                      How can I get {{ sti.name }}?
                    </div>
                  </v-fade-transition>
                </template>
                <template v-slot:actions="{ expanded }">
                  <v-icon
                    :color="expanded ? '#000000' : primaryColor"
                    :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                  ></v-icon>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <div v-html="sti.howCanIGetIt"></div>
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel
              v-if="sti.whatDoesItLookLike"
              class="mb-2"
              elevation="0"
              :style="{ border: `2px solid ${primaryColor}` }"
            >
              <v-expansion-panel-title>
                <template v-slot:default="{ expanded }">
                  <v-fade-transition leave-absolute>
                    <div
                      class="font-weight-bold"
                      :style="{ color: expanded ? primaryColor : '#000000' }"
                    >
                      What does {{ sti.name }} look like?
                    </div>
                  </v-fade-transition>
                </template>
                <template v-slot:actions="{ expanded }">
                  <v-icon
                    :color="expanded ? '#000000' : primaryColor"
                    :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                  ></v-icon>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <div v-html="sti.whatDoesItLookLike"></div>
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel
              v-if="sti.whatDoesItFeekLike"
              class="mb-2"
              elevation="0"
              :style="{ border: `2px solid ${primaryColor}` }"
            >
              <v-expansion-panel-title>
                <template v-slot:default="{ expanded }">
                  <v-fade-transition leave-absolute>
                    <div
                      class="font-weight-bold"
                      :style="{ color: expanded ? primaryColor : '#000000' }"
                    >
                      What does {{ sti.name }} feel like?
                    </div>
                  </v-fade-transition>
                </template>
                <template v-slot:actions="{ expanded }">
                  <v-icon
                    :color="expanded ? '#000000' : primaryColor"
                    :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                  ></v-icon>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <div v-html="sti.whatDoesItFeekLike"></div>
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel
              v-if="sti.howCanITestForIt"
              class="mb-2"
              elevation="0"
              :style="{ border: `2px solid ${primaryColor}` }"
            >
              <v-expansion-panel-title>
                <template v-slot:default="{ expanded }">
                  <v-fade-transition leave-absolute>
                    <div
                      class="font-weight-bold"
                      :style="{ color: expanded ? primaryColor : '#000000' }"
                    >
                      How do I test for {{ sti.name }}?
                    </div>
                  </v-fade-transition>
                </template>
                <template v-slot:actions="{ expanded }">
                  <v-icon
                    :color="expanded ? '#000000' : primaryColor"
                    :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                  ></v-icon>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <div v-html="sti.howCanITestForIt"></div>
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel
              v-if="sti.canItBeCured"
              class="mb-2"
              elevation="0"
              :style="{ border: `2px solid ${primaryColor}` }"
            >
              <v-expansion-panel-title>
                <template v-slot:default="{ expanded }">
                  <v-fade-transition leave-absolute>
                    <div
                      class="font-weight-bold"
                      :style="{ color: expanded ? primaryColor : '#000000' }"
                    >
                      Can {{ sti.name }} be cured?
                    </div>
                  </v-fade-transition>
                </template>
                <template v-slot:actions="{ expanded }">
                  <v-icon
                    :color="expanded ? '#000000' : primaryColor"
                    :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                  ></v-icon>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <div v-html="sti.canItBeCured"></div>
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel
              v-if="sti.howIsItTreated"
              class="mb-2"
              elevation="0"
              :style="{ border: `2px solid ${primaryColor}` }"
            >
              <v-expansion-panel-title>
                <template v-slot:default="{ expanded }">
                  <v-fade-transition leave-absolute>
                    <div
                      class="font-weight-bold"
                      :style="{ color: expanded ? primaryColor : '#000000' }"
                    >
                      How is {{ sti.name }} treated?
                    </div>
                  </v-fade-transition>
                </template>
                <template v-slot:actions="{ expanded }">
                  <v-icon
                    :color="expanded ? '#000000' : primaryColor"
                    :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                  ></v-icon>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <div v-html="sti.howIsItTreated"></div>
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel
              v-if="sti.ifNotTreated"
              class="mb-2"
              elevation="0"
              :style="{ border: `2px solid ${primaryColor}` }"
            >
              <v-expansion-panel-title>
                <template v-slot:default="{ expanded }">
                  <v-fade-transition leave-absolute>
                    <div
                      class="font-weight-bold"
                      :style="{ color: expanded ? primaryColor : '#000000' }"
                    >
                      What happens if {{ sti.name }} is not treated?
                    </div>
                  </v-fade-transition>
                </template>
                <template v-slot:actions="{ expanded }">
                  <v-icon
                    :color="expanded ? '#000000' : primaryColor"
                    :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                  ></v-icon>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <div v-html="sti.ifNotTreated"></div>
              </v-expansion-panel-text>
            </v-expansion-panel>

            <v-expansion-panel
              v-if="sti.withPregnancy"
              class="mb-2"
              elevation="0"
              :style="{ border: `2px solid ${primaryColor}` }"
            >
              <v-expansion-panel-title>
                <template v-slot:default="{ expanded }">
                  <v-fade-transition leave-absolute>
                    <div
                      class="font-weight-bold"
                      :style="{ color: expanded ? primaryColor : '#000000' }"
                    >
                      <span class="text-capitalize">{{ sti.name }}</span> and
                      pregnancy?
                    </div>
                  </v-fade-transition>
                </template>
                <template v-slot:actions="{ expanded }">
                  <v-icon
                    :color="expanded ? '#000000' : primaryColor"
                    :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                  ></v-icon>
                </template>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <div v-html="sti.withPregnancy"></div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-col>
    </v-row>

    <v-row class="my-4 mx-1">
      <v-col cols="12" align-center lg="12" sm="12">
        <div
          class="text-h6 font-weight-bold mb-2"
          :style="{ color: primaryColor }"
        >
          Cervical cancer and HPV vaccines
        </div>
        <div>
          <p>
            The type of HPV that causes warts is usually not dangerous, other
            than causing warts. However, HPV is the leading cause of cervical
            cancer, a kind of cancer very common in women in South Africa.
          </p>
          <br />
          <p>
            An effective HPV vaccine that can help protect girls and women is
            now given to all girls in primary school. Also, in South Africa
            there is a free national screening programme involving a pap smear
            or test for cervical cancer.
          </p>
          <br />
          <p>
            Go to your local clinic for a pap smear if you are over the age of
            30.
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row class="my-4 mx-1">
      <v-col cols="12" align-center lg="12" sm="12">
        <div
          class="text-h6 font-weight-bold mb-2"
          :style="{ color: primaryColor }"
        >
          Do you have pain in the bottom part of your stomach?
        </div>
        <div>
          <p>
            This can be a sign of something serious and you should go to your
            clinic to be assessed by a health care provider, especially if:
          </p>
          <ul class="mx-5">
            <li>The pain started suddenly</li>
            <li>Your last period was more than a month ago</li>
            <li>You are not on any contraception</li>
            <li>You have a fever or discharge</li>
          </ul>
        </div>
      </v-col>
    </v-row>

    <v-row class="my-4 mx-1"
      >mb-2
      <v-col cols="12" align-center lg="12" sm="12">
        <div
          class="text-h6 font-weight-bold mb-2"
          :style="{ color: primaryColor }"
        >
          Get treated today
        </div>
        <div>
          <p>
            For girls and women, leaving STIs untreated can lead to PID or
            pelvic inflammatory disease. PID is when the infection spreads into
            the upper reproductive organs (uterus, ovaries, and fallopian
            tubes). It can cause pain in your stomach and genitals, make it hard
            to get pregnant later, or result in an ectopic pregnancy, which is
            when the fetus develops outside your uterus (womb).
          </p>
          <br />
          <p>
            If you have an untreated STI and fall pregnant, you could also pass
            the infection on to your baby during pregnancy or delivery.
          </p>
          <br />
          <p>
            In men and women, leaving some STIs like gonorrhoea untreated can
            lead to joint infections and arthritis. Many STIs can increase the
            likelihood of becoming infected with HIV.
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row class="my-4 mx-1">
      <v-col cols="12" align-center lg="12" sm="12">
        <div
          class="text-h6 font-weight-bold mb-2"
          :style="{ color: primaryColor }"
        >
          I have an STI. How do I tell my partner?
        </div>
        <div>
          <p>
            If you have an STI, then you need to tell the people you could have
            possibly infected so they can get treatment too. A health care
            provider can help you figure out which partner(s) you need to tell
            based on when you last had sex with them.
          </p>
          <br />
          <p>
            If you go to a clinic and get treatment for an STI, they will
            usually give you a note to give to your partner(s). You will usually
            be asked to take that note home and give it to your partner(s) so
            that they can go to the clinic and get the same treatment as you.
            This is very important because if they are not treated, they will
            probably infect you again with the same STI after you have completed
            treatment. Their health is also at risk, so they need to know what
            is going on.
          </p>
          <br />
          <p>
            It is natural to feel worried, embarrassed, and even scared. But to
            protect your partner(s), it is a conversation you need to have.
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row class="my-4 mx-1">
      <v-col cols="12" align-center lg="12" sm="12">
        <div
          class="text-h6 font-weight-bold mb-2"
          :style="{ color: primaryColor }"
        >
          Some ideas on how to tell your partner
        </div>
        <div>
          <p>
            Be direct. You could start by saying, “Before we have sex, I want us
            to talk about STIs and protection because I have an STI”. Say what
            type of STI you have.
          </p>
          <br />
          <p>
            Let the conversation continue naturally. Listen rather than doing
            all the talking. Prepare for your partner to be surprised. Each
            person reacts differently to the news. Some might have a lot of
            questions. Try your best to be honest and answer these. Some might
            just need some time to think. Some might get angry.
          </p>
          <br />
          <p>
            If you are worried your partner may become angry or aggressive, you
            can ask your health care provider if you can bring them to the
            clinic so that you can tell them together.
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row class="my-4 mx-1">
      <v-col cols="12" align-center lg="12" sm="12">
        <div
          class="text-h6 font-weight-bold mb-2"
          :style="{ color: primaryColor }"
        >
          My partner told me they have an STI. What now?
        </div>
        <div>
          <p>
            Finding out your partner has an STI can leave you feeling uneasy,
            maybe even angry.
          </p>
          <br />
          <div>
            You might have lots of questions you need answered, such as:
            <div>
              <li>Has my partner been unfaithful?</li>
              <li>Am I at risk of having an STI?</li>
              <li>Is it curable?</li>
            </div>
          </div>
          <br />
          <p>
            Your partner could have an STI because they were with another person
            and did not use protection. But they could also have had the STI
            before they got into a relationship with you, and they may or may
            not have known about it. You could also be the one that gave your
            partner an STI and did not know you had it because you did not have
            any symptoms.
          </p>
          <br />
          <p>
            Either way, it’s better not to play the blame game and rather focus
            on you both getting treatment.
          </p>
          <br />
          <p>
            If you have been using a condom the whole time you have been
            together, you are probably safe. But if at some point you had
            condomless sex or if a condom broke, you may be at risk of getting
            an STI.
          </p>
          <br />
          <p>
            The first thing you need to do is visit your nearest clinic to get
            tested for STIs. Just because you have not seen any signs of STIs,
            does not mean you do not have any. Some STI symptoms are not
            obvious, and if the infection is not treated it could affect your
            health or even put your life at risk.
          </p>
          <br />
          <p>
            It is important to visit the clinic and get checked. You can get
            treatment if you need it, and this will help prevent passing the
            infection to anyone else.
          </p>
          <br />
          <p>
            To help protect yourself from STIs, use a condom every time you have
            sex. Male/external and female/internal condoms are available free of
            charge from clinics.
          </p>
          <br />
          <p>
            The only way to protect yourself against STIs if you are having sex
            is to always use a condom.
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-2">
      <v-col class="d-flex flex-row align-center justify-space-around">
        <v-btn
          class="text-white rounded-pill"
          size="large"
          @click="$router.go(-1)"
          color="customfuschia"
        >
          BACK
        </v-btn>
        <v-btn
          class="text-white rounded-pill"
          size="large"
          color="customfuschia"
          @click="navigateToServiceFinder"
        >
          FIND A CLINIC
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HeaderBar from "../components/HeaderBar.vue";
export default {
  name: "STIManagement",
  components: { HeaderBar },

  data: () => ({
    panel: [0, 1],
    disabled: false,
    readonly: false,
    activeMethod: {
      whatIsIt:
        "STI management is routine screening and treatment to avoid STIs going untreated. STI symptoms, such as infections and open sores, can increase the likelihood of getting HIV if a person is exposed when having sex. Though STI screening and treatment do not prevent HIV, it can help reduce the likelihood of getting HIV.",
      howDoesItWork:
        "If a person has an STI, they could have been exposed to HIV as well through the sexual practice that gave them the STI. Screening for STIs may alert them to the need to be tested for HIV, to use HIV prevention methods, and to seek treatment for STIs to make them less likely to get HIV.",
      howIsItUsed:
        "Preventing STIs may reduce the likelihood of getting HIV. Screening for STIs regularly is recommended; your health care provider will encourage you to be aware of the signs and symptoms. Screening is especially important and should be done routinely if you are having condomless sex. Not all STIs have symptoms, so routine laboratory testing (if available at your health care facility) should be done to diagnose asymptomatic infections. The key to effective STI management is to be screened (test where possible) regularly, complete any STI treatment when needed, ensure that your sexual partners are screened and treated, and remember that condoms are the most effective way to prevent STIs.",
      howWellDoesItWork:
        "STI management is different from most other HIV prevention methods in that it does not prevent HIV but instead lowers the likelihood of getting HIV. Oral PrEP may also be a good HIV prevention option to consider — together with condoms — to prevent HIV.",
      whatAreTheSideEffect:
        "Going for an STI screening does not have any side effects, however treatment for an STI may include some medications that may have mild side effects.",
      considerations: [
        {
          text: "If a person has an STI and receives treatment, it is important to complete the full course of treatment and to notify their sexual partners to test and potentially receive treatment too, otherwise a person will become re-infected.",
          position: 0,
        },
        {
          text: "Unfortunately, not all STIs can be cured, but this should not deter the person from getting tested as these STIs can be managed.",
          position: 1,
        },
        {
          text: "STI testing and treatment lowers the likelihood of getting HIV, but is not an effective prevention method on its own.",
          position: 2,
        },
      ],
      faqs: [
        {
          question: "Can a person get tested for STIs at their nearest clinic?",
          answer:
            "Not all health care facilities offer STI testing but are likely to provide STI screening and treatment. A health care provider can inform a person on where to access testing.",
          position: 0,
        },
        {
          question: "What are the ways that a person can get an STI?",
          answer:
            "A person can get an STI through vaginal, anal, or oral sex and all require management.",
          position: 1,
        },
        {
          question:
            "Any concerns when using contraceptives or other medications?",
          answer: "None.",
          position: 2,
        },
      ],
    },
    whatsWrong: [
      {
        question: "I have a discharge from my penis or vagina.",
        answer: [
          "Chlamydia",
          "Gonorrhea",
          "Trichomoniasis",
          "Thrush",
          "Bacterial vaginosis",
        ],
        position: 0,
      },
      {
        question: "I have pain in the lower part of my stomach.",
        answer: ["Chlamydia", "Gonorrhea"],
        position: 1,
      },
      {
        question: "I am experiencing abnormal vaginal bleeding or spotting.",
        answer: ["Chlamydia", "Gonorrhea"],
        position: 2,
      },
      {
        question: "My penis or vagina is burning or itching.",
        answer: [
          "Chlamydia",
          "Gonorrhea",
          "Trichomoniasis",
          "Thrush",
          "Urinary tract infection (UTI)",
        ],
        position: 3,
      },
      {
        question:
          "The skin around my penis, vagina, or anus is irritated or red.",
        answer: ["Thrush"],
        position: 4,
      },
      {
        question: "I have growths on my penis, vagina, or anus.",
        answer: ["Warts"],
        position: 5,
      },
      {
        question: "I have ulcers or sores on my penis or vagina.",
        answer: ["Herpes", "Syphilis", "Other causes which are not STIs"],
        position: 6,
      },
      {
        question: "It burns when I pee.",
        answer: [
          "Chlamydia",
          "Gonorrhea",
          "Trichomoniasis",
          "Thrush",
          "Urinary tract infection (UTI)",
        ],
        position: 7,
      },
    ],
    stis: [
      {
        name: "chlamydia",
        heading: "",
        whatIsIt:
          "Chlamydia is the most common STI in South Africa after HIV. It is caused by bacteria.",
        howCanIGetIt:
          "This STI spreads through having condomless sex (vaginal, anal, or oral) with someone who has the infection.",
        whatDoesItLookLike:
          "You may have chlamydia and not know it because you do not have symptoms or are showing physical signs. This means that you can continue to spread it through condomless sex. Signs or symptoms of chlamydia may include: <ul class='mx-5'><li>	A thick yellow discharge (a creamy liquid which can be like pus) from your penis, vagina, or anus </li><li>	Pain or burning when peeing</li><li>Pain or bleeding during sex</li><li>Painful testicles</li><li>Fever</li><li>Stomach pain</li><li>If you have had anal sex, you may have pain, discharge, and bleeding from your anus</li></ul>",
        howCanITestForIt:
          "Clinics do not routinely test for chlamydia. Instead, health care providers give antibiotics to treat it (and other potential STIs) if you have symptoms. If your provider does a test, it may be either with a urine sample or a swab of the inside of the vagina or the urethra of the penis, which is sent to a laboratory for testing.",
        canItBeCured: "",
        howIsItTreated:
          "Chlamydia is treated with antibiotics. If you have been started on antibiotics, do not have oral, vaginal, or anal sex, or use sex toys, until you and your partner(s) have finished the treatment and all symptoms are gone. This is to help prevent you being re-infected or passing the infection on to someone else. You and your partner(s) still need to avoid sex for seven days after starting the treatment as that is how long it takes to work.<br /> <br /> If you have chlamydia, you should tell the person you last had sex with and anyone you have had condomless sex with in the past two to three months. They may also be infected and need treatment. <br /> <br />Note: do not wait too long to get treatment. If you have chlamydia and leave it untreated, it can cause long-term problems for both men and women. In women, it can lead to PID or pelvic inflammatory disease. PID is when the infection spreads into the upper reproductive organs (uterus, ovaries, and fallopian tubes). It can cause pelvic and abdominal pain, make it hard to get pregnant later, or result in ectopic pregnancies – when the fetus develops outside the uterus (womb). If you are infected when pregnant, you could also pass the infection to your baby during delivery.",
        withPregnancy: "",
      },
      {
        name: "gonorrhoea",
        heading: "It could be gonorrhoea",
        whatIsIt:
          "Gonorrhoea is an STI caused by bacteria. The bacteria that cause gonorrhea like to live in warm, wet places, such as the mouth, eyes, penis, vagina, and anus.",
        howCanIGetIt:
          "You can get gonorrhea if you come into contact with infected semen (cum or pre-cum) or infected discharge from the vagina, throat, or anus during condomless sex.",
        whatDoesItLookLike:
          "You may have gonorrhea and not know it because you do not have any symptoms. This means you can continue to spread it to your partner(s) through condomless sex. If you do have gonorrhoea, signs or symptoms may include: <ul class='mx-5'><li>A discharge (creamy liquid which can be like pus) coming from your penis or vagina or anus</li><li>Needing to pee more often than usual</li><li>Pain in your penis or vagina, especially during sex or after ejaculation (cumming)</li><li>If you have had anal sex, you may experience a discharge, pain, or bleeding from your anus</li><li>A sore throat</li><li>Fever</li><ul>",
        whatDoesItFeekLike: "",
        howCanITestForIt:
          "Health care providers at clinics do not routinely test for gonorrhea. Instead, they give antibiotics if you have physical signs and symptoms of an STI. If you go to a private doctor or facility, testing may include a urine sample or a swab of the inside of the vagina or penis, which is then sent to a laboratory for testing.",
        canItBeCured: "",
        howIsItTreated:
          "Gonorrhea is treated with antibiotics. If you have gonorrhoea, you should tell the person who you last had sex with and anyone you had condomless sex with in the past two to three months. They may also be infected and need treatment. If you are diagnosed with gonorrhea, then you need to stop having sex until seven days after you have finished treatment.",
        ifNotTreated:
          "If you leave gonorrhea untreated, it can cause long-term problems for both men and women. In women, it can lead to PID or pelvic inflammatory disease. PID is when the infection spreads into the upper reproductive organs (uterus, ovaries, and fallopian tubes). It can cause pelvic and abdominal pain, make it hard to get pregnant later, or result in ectopic pregnancies – when the fetus develops outside the uterus (womb). In both men and women, untreated gonorrhea can spread to the joints and cause arthritis. <br /><br /> If you are infected when pregnant, you could pass the infection to your baby during delivery. <br /> <br />Gonorrhea can also increase the likelihood of becoming infected with HIV.",
        withPregnancy: "",
      },
      {
        name: "trichomoniasis",
        heading: "It could be trichomoniasis",
        whatIsIt: "Trichomoniasis is an STI caused by a parasite.",
        howCanIGetIt:
          "You can get infected with trichomoniasis through condomless vaginal or anal sex when there is an exchange of body fluids. Anyone who is sexually active can get it and pass it on. You do not need to have a lot of partners to get infected.",
        whatDoesItLookLike:
          "If you have trichomoniasis, you may develop:<ul class='mx-5'><li>A yellow or green, often pungent, discharge from the vagina</li><li>Pain or burning when urinating</li><li>Pain or bleeding during sex</li><li>Pain in the lower part of your stomach</li></ul>",
        whatDoesItFeekLike: "",
        howCanITestForIt:
          "Health care providers at clinics do not routinely test for trichomoniasis. Instead, they give antibiotics to treat it if you have physical symptoms or signs of infection. If you choose to go to a private doctor or facility for testing, the test may include either a urine sample or a swab of the inside of the vagina, which is sent to a laboratory for testing.",
        canItBeCured: "",
        howIsItTreated:
          "Trichomoniasis is treated using antibiotics. If you learn that you have trichomoniasis, you should contact all the people you have had sex with recently to let them know. They might have trichomoniasis, too. They will need treatment even if they have no symptoms. ",
        ifNotTreated:
          "If you do not get treatment for trichomoniasis, your symptoms can get worse and you will keep spreading the infection to your sexual partner(s). <br /><br /> You might also have a higher chance of getting HIV. <br /><br /> If you become pregnant and have an untreated trichomoniasis infection, it can cause the baby to be born early.",
        withPregnancy: "",
      },
      {
        name: "vaginosis",
        heading: "It could be vaginosis",
        whatIsIt:
          "Bacterial vaginosis is an infection that affects women and is caused by bacteria that normally live in the vagina. When the amount or types of these bacteria change or increase, it causes an infection. Certain factors such as vaginal douching and having condomless sex may increase the likelihood of bacterial vaginosis. Vaginal douching is the use of feminine hygiene products to wash inside the vagina.",
        howCanIGetIt: "",
        whatDoesItLookLike:
          "If you have bacterial vaginosis, it can cause:<ul class='mx-5'><li>A bad-smelling discharge, often “fishy” smelling</li><li>A watery and grey or off-white discharge</li><li>Burning or itching of the skin around the vagina</li><li>Burning during urination</li><li>Pain during vaginal sex</li></ul>Not all infections are sexually transmitted, but it is not always easy to tell the difference. It is important that you and your partner(s) see a health care provider if you experience any symptoms so you can be examined and treated for all infections. Avoid having condomless sex when you have physical signs or symptoms that might be due to an infection.",
        whatDoesItFeekLike: "",
        howCanITestForIt:
          "It is usually diagnosed through an examination by a health care provider. They may take a sample of the fluid in the vagina and send it to a laboratory for testing, but this is not routinely done in South Africa. ",
        canItBeCured: "",
        howIsItTreated:
          "It is usually treated with antibiotics. Seek care as soon as possible if you have any symptoms or if you are concerned.",
        ifNotTreated: "",
        withPregnancy: "",
      },
      {
        name: "trush",
        heading: "It could be trush",
        whatIsIt:
          "Thrush is an infection caused by a fungus called candida. Candida lives in the vagina and gut (which includes the stomach). Medicines (such as antibiotics), stress, high sugar from diabetes, and other factors like vaginal douching or wearing tight underwear can cause the fungus to grow more than it should, which is when thrush can start. Vaginal douching is using feminine hygiene products to wash inside your vagina.",
        howCanIGetIt: "",
        whatDoesItLookLike:
          "<ul class='mx-5'><li>Itching and irritation of the lips of the vagina and inside the vagina<li><li>Pain when you pee</li><li>Pain when having sex</li><li>A white/creamish discharge that can be thick and look a bit like cottage cheese </li></ul>",
        whatDoesItFeekLike: "",
        howCanITestForIt:
          "A health care provider will usually be able to recognise this infection by examining you. Further tests are not normally necessary.",
        canItBeCured: "",
        howIsItTreated:
          "It is treated with medication, which usually comes in the form of a cream, you put into the vagina and around the vaginal lips. It can also be treated with a pill, which you swallow.",
        ifNotTreated: "",
        withPregnancy: "",
      },
      {
        name: "a UTI",
        heading: "It could be a UTI",
        whatIsIt:
          "A urinary tract infection (UTI) is caused by bacteria that normally live in the gut (which includes the stomach). The bacteria enter the urethra, the tube that carries pee from the bladder to the outside of the body. The bacteria moves up this tube to infect the bladder.",
        howCanIGetIt: "",
        whatDoesItLookLike: "",
        whatDoesItFeekLike:
          "What does a UTI feel like? <ul class='mx-5'><li>Pain or a burning sensation when you pee<li><li>The need to pee often</li><li>The need to pee suddenly</li><li>Blood in the urine</li><HeaderBar</ul>",
        howCanITestForIt:
          "A health care provider will be able to tell if you have a UTI from your physical complaints and an examination. They may also take a urine sample to test for bacteria.",
        canItBeCured: "",
        howIsItTreated:
          "Most urinary tract infections are treated with antibiotics. ",
        ifNotTreated: "",
        withPregnancy: "",
      },
      {
        name: "herpes",
        heading: "It could be herpes",
        whatIsIt:
          "Herpes is an infection of the skin around your mouth, penis, vagina, or anus caused by a virus. It is one of the most common STIs and the most common cause of genital ulcers (sores on the penis or vagina) in South Africa. Some studies have shown that 50% of women in South Africa have herpes.",
        howCanIGetIt:
          "Once you have herpes, the virus stays in your body, so you can pass it to others through the exchange of body fluids when you have oral, vaginal, or anal sex, especially if you have a sore at the time. Any skin-to-skin contact with infected areas can pass along herpes (if the virus is active on the skin outside of the area covered by a condom), even if the person who has herpes does not have any visible sores or other symptoms. ",
        whatDoesItLookLike:
          "If you have herpes, you may not experience any symptoms and you may not know that you have it. But many people who have herpes get painful blisters or sores on the lips, inside the mouth, or on the vagina, penis, or anus. It can also cause fever, headaches, painful joints, and trouble urinating.",
        whatDoesItFeekLike: "",
        howCanITestForIt:
          "In South Africa, there is no routine test for herpes. It is diagnosed based on your physical complaints or signs and symptoms. If you go to a private doctor or clinic, the health care provider can test you by either taking a sample of fluid from the sore and sending it to a laboratory or by doing a blood test to determine if you were exposed to herpes previously. ",
        canItBeCured:
          "Herpes cannot be cured, but the disease usually causes the most problems during the first few years of infection. After that, the virus is still in your body, but it causes few to no symptoms. Even when the virus is active, people with herpes can take medicine to reduce and help prevent symptoms.",
        howIsItTreated:
          "Your local health care provider can prescribe medicine to help reduce symptoms and speed up the healing of an outbreak. The medicine works best taken as soon as the person feels an outbreak is about to start or at first appearance.",
        ifNotTreated: "",
        withPregnancy:
          "If you are pregnant or planning to become pregnant and have herpes, consult a health care provider. It is possible for a baby to get herpes from its mother during birth, especially if the mother’s first ulcer or infection starts near the time of delivery. Your doctor will tell you how best to avoid this.",
      },
      {
        name: "syphilis",
        heading: "It could be syphilis",
        whatIsIt:
          "Syphilis is an STI caused by bacteria. It has become less common in South Africa over the past 10 years, but in 2011 about 1.6% of pregnant women had syphilis when they were tested at the beginning of pregnancy.",
        howCanIGetIt:
          "You can get infected with syphilis by having condomless oral, vaginal, or anal sex, or by kissing someone who has a syphilis sore on their mouth. You can pass syphilis on without knowing you have it because symptoms can be mild and you may not notice or recognise them.",
        whatDoesItLookLike:
          "Syphilis usually causes painless sores or rashes on the skin, which can become serious if left too long. If you have syphilis and leave it untreated over months to years, it can also cause serious damage to the heart, skin, brain, and other organs.",
        whatDoesItFeekLike: "",
        howCanITestForIt:
          "If your health care provider suspects you have syphilis, a simple blood sample will be taken and sent to a laboratory.",
        canItBeCured: "",
        howIsItTreated:
          "If you are diagnosed with syphilis, you will receive an antibiotic injection of penicillin. If you are allergic to penicillin, you can be treated with an antibiotic called doxycycline for at least two weeks.",
        ifNotTreated: "",
        withPregnancy:
          "If you become pregnant and have undiagnosed syphilis, it can cause a miscarriage, premature birth, or stillbirth. It is also possible for an infected pregnant woman to give syphilis to her baby, resulting in many health problems in the baby, including early death in newborns. Because of the health problems due to syphilis in babies, all pregnant women in South Africa undergo two tests for syphilis during the course of their pregnancy.",
      },
      {
        name: "warts",
        heading: "It could be warts",
        whatIsIt:
          "Human papillomavirus (HPV) is one of the most common STIs. Up to 70% of sexually active people test positive for HPV in South Africa. There are many different types of HPV; some cause problems such as genital warts and cervical cancer",
        howCanIGetIt:
          "HPV is passed from one person to another through condomless oral, vaginal, or anal sex. It can live in cells on top of the skin on many body parts, such as the vagina, anus, vulva, cervix, penis, mouth, or throat. If you touch an infected person’s skin or body fluids, you are also at risk of infection. <br /> <br /> Using condoms can lower your chances of getting HPV, but the virus can sometimes infect areas not covered by a condom — so condoms may not fully protect against getting HPV. HPV can cause warts. Warts are small, skin-coloured growths that form on the lips of the vagina, inside the vagina, on the penis, and on the anus. They spread from person to person through condomless sex.",
        whatDoesItLookLike: "",
        whatDoesItFeekLike: "",
        howCanITestForIt:
          "A health care provider will be able to tell if you have a wart by examining the growth.",
        canItBeCured: "",
        howIsItTreated:
          "There are several medicines for treating warts. Your health care provider will usually give you lotions to apply a few times a week. Sometimes, if you have a very big wart or lots of warts, they may need to be removed. Please visit your closest clinic for treatment. Treatment does not always make the warts go away forever or completely cure them. The medicines are used to make the warts smaller and more comfortable for you, but they can often come back again.",
        ifNotTreated: "",
        withPregnancy:
          "Pregnancy can sometimes make warts worse. Please go to your clinic if you develop warts while pregnant.",
      },
    ],
    primaryColor: "#0057a7",
  }),
  async created() {},
  computed: {},
  methods: {
    navigateToServiceFinder() {
      this.$gtag.event("Navigation to Service Finder", {
        event_category: "navigation_to_service_finder",
        event_label: "navigation_to_service_finder_from_sti_page",
        value: "sti_page",
      });
      window.open(
        "https://witsrhi-servicefinder-client.onrender.com/",
        "_blank",
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.v-expansion-panel-title__icon, .v-expansion-panel-title
  color: black !important
// table, th, td
//   border:1px solid black
.faqpanel:deep(.v-expansion-panel-text__wrapper)
  padding: 0 0 0 0 !important

.even
  background: #ffffff
.odd
  background: #efede4
</style>
