<template>
  <v-container>
    <header-bar></header-bar>
    <v-row v-if="finalMethods.length > 0" class="my-4 mx-1">
      <v-col cols="12" align-center lg="12" sm="12">
        <div class="custom-fuschia text-h5 font-weight-bold mb-3">
          Your results
        </div>
        <div>
          <span
            >Based on your selection, the following methods could work for
            you:</span
          >
        </div>
      </v-col>
    </v-row>
    <v-row v-if="finalMethods.length > 0" class="mx-4">
      <v-col
        cols="4"
        v-for="(method, index) in finalMethods"
        :key="index"
        @click="goToMethodDetailsPage(getMethod(method).id)"
      >
        <!-- <div>
          <v-btn rounded color="pink">{{ method }}</v-btn>
        </div> -->
        <!-- <div class="d-flex flex-row"> -->
        <div class="d-flex flex-column text-center justify-center align-center">
          <v-img
            contain
            :aspect-ratio="1"
            min-width="65"
            width="65"
            max-height="65"
            :src="getMethod(method).imageUrl"
          >
          </v-img>
          <div
            class="mt-2 font-weight-bold text-decoration-underline"
            :style="{ color: getMethod(method).color || '#000000' }"
          >
            {{ getMethod(method).name }}
          </div>
        </div>
        <!-- <v-img
            contain
            min-width="75"
            width="75"
            max-height="75"
            :src="getMethod(method).imageUrl"
          >
          </v-img> -->
        <!-- <v-icon
            v-if="index < finalMethods.length - 1"
            class="plusicon"
            icon="mdi-plus-thick"
            size="x-large"
            color="customfuschia"
          ></v-icon> -->
        <!-- </div> -->
      </v-col>
    </v-row>
    <v-row v-if="finalMethods.length > 0" class="mx-1 mt-4">
      <v-col
        v-for="(method, index) in finalMethods"
        :key="index"
        cols="12"
        align-center
        lg="12"
        sm="12"
      >
        <div @click="goToMethodDetailsPage(getMethod(method).id)">
          <span
            class="font-weight-bold text-decoration-underline"
            :style="{ color: getMethod(method).color || '#000000' }"
            >{{ getMethod(method).name }}:
          </span>
          <span v-html="getMethod(method).resultText"> </span>
        </div>
      </v-col>
    </v-row>
    <v-row v-else class="my-4 mx-1">
      <v-col cols="12" align-center lg="12" sm="12">
        <div class="custom-fuschia text-h5 font-weight-bold mb-3">
          Your results
        </div>
        <v-img
          class="mb-3"
          contain
          :aspect-ratio="1"
          width="75"
          src="@/assets/icons/none.png"
        >
        </v-img>
        <div>
          <span>
            Based on your selection, we could not find a combination of methods
            that could work for you.
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col class="d-flex flex-row align-center justify-space-around">
        <v-btn
          class="text-white rounded-pill"
          size="large"
          @click="goQuizPageOne"
          color="customfuschia"
        >
          <h6>BACK</h6>
        </v-btn>
        <v-btn
          class="text-white rounded-pill"
          size="large"
          color="customfuschia"
          @click="navigateToWhatsapp()"
        >
          <h6>
            TALK TO<br />
            SOMEBODY
          </h6>
        </v-btn>
        <v-btn
          class="text-white rounded-pill"
          size="large"
          color="customfuschia"
          @click="navigateToServiceFinder"
        >
          <h6>FIND A CLINIC</h6>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import QuestionDataService from "../services/QuestionDataService";
import MethodDataService from "../services/MethodDataService";
import HeaderBar from "../components/HeaderBar.vue";
import { mapGetters } from "vuex";
export default {
  name: "QuizResultsPage",
  components: { HeaderBar },
  data: () => ({
    allQuestions: [],
    allMethods: [],
    finalMethods: [],
  }),
  async created() {
    const self = this;
    this.finalMethods = [];
    this.allQuestions = await QuestionDataService.getAll();
    this.allMethods = await MethodDataService.getAll();

    console.log("QuizPageOneChoice", this.QuizPageOneChoice);
    console.log("QuizPageTwoChoice", this.QuizPageTwoChoice);
    console.log("QuizPageThreeChoice", this.QuizPageThreeChoice);

    if (this.QuizPageOneChoice && this.QuizPageTwoChoice) {
      /***********GET QUIZ PAGE 1 METHODS */
      var QuizPageOneQuestion = this.allQuestions.find(
        (question) => question.id === this.QuizPageOneChoice,
      );
      console.log("QuizPageOneQuestion", QuizPageOneQuestion);
      const QuizPageOneQuestionPositiveMethods =
        QuizPageOneQuestion && QuizPageOneQuestion.positiveMethods
          ? QuizPageOneQuestion.positiveMethods
          : [];
      const QuizPageOneQuestionNegativeMethods =
        QuizPageOneQuestion && QuizPageOneQuestion.negativeMethods
          ? QuizPageOneQuestion.negativeMethods
          : [];

      console.log(
        "QuizPageOneQuestion",
        QuizPageOneQuestionPositiveMethods,
        QuizPageOneQuestionNegativeMethods,
      );
      /********************************************* */
      /***********GET QUIZ PAGE 2 METHODS */
      var QuizPageTwoQuestion = this.allQuestions.find(
        (question) => question.id === this.QuizPageTwoChoice,
      );
      console.log("QuizPageTwoQuestion", QuizPageTwoQuestion);
      const QuizPageTwoQuestionPositiveMethods =
        QuizPageTwoQuestion && QuizPageTwoQuestion.positiveMethods
          ? QuizPageTwoQuestion.positiveMethods
          : [];
      const QuizPageTwoQuestionNegativeMethods =
        QuizPageTwoQuestion && QuizPageTwoQuestion.negativeMethods
          ? QuizPageTwoQuestion.negativeMethods
          : [];

      console.log(
        "QuizPageTwoQuestion",
        QuizPageTwoQuestionPositiveMethods,
        QuizPageTwoQuestionNegativeMethods,
      );
      /********************************************* */
      /***********GET QUIZ PAGE 3 METHODS */
      var QuizPageThreeQuestion = this.allQuestions.find(
        (question) => question.id === this.QuizPageThreeChoice,
      );
      console.log("QuizPageThreeQuestion", QuizPageThreeQuestion);
      const QuizPageThreeQuestionPositiveMethods =
        QuizPageThreeQuestion && QuizPageThreeQuestion.positiveMethods
          ? QuizPageThreeQuestion.positiveMethods
          : [];
      const QuizPageThreeQuestionNegativeMethods =
        QuizPageThreeQuestion && QuizPageThreeQuestion.negativeMethods
          ? QuizPageThreeQuestion.negativeMethods
          : [];

      console.log(
        "QuizPageThreeQuestion",
        QuizPageThreeQuestionPositiveMethods,
        QuizPageThreeQuestionNegativeMethods,
      );

      /********************************************* */

      const allPositiveMethods = [
        ...QuizPageOneQuestionPositiveMethods,
        ...QuizPageTwoQuestionPositiveMethods,
        ...QuizPageThreeQuestionPositiveMethods,
      ];

      const allNegativeMethods = [
        ...QuizPageOneQuestionNegativeMethods,
        ...QuizPageTwoQuestionNegativeMethods,
        ...QuizPageThreeQuestionNegativeMethods,
      ];

      console.log("allPositiveMethods", allPositiveMethods);
      console.log("allNegativeMethods", allNegativeMethods);

      const uniquePositiveMethods = new Set(allPositiveMethods);
      const uniqueNegativeMethods = new Set(allNegativeMethods);
      const positiveMethodsThatShouldBeRetained = new Set();

      uniquePositiveMethods.forEach(function (method) {
        if (self.getMethod(method).retainMethod) {
          positiveMethodsThatShouldBeRetained.add(method);
        }
      });

      console.log("uniquePositiveMethods", uniquePositiveMethods);
      console.log("uniqueNegativeMethods", uniqueNegativeMethods);
      console.log(
        "positiveMethodsThatShouldBeRetained",
        positiveMethodsThatShouldBeRetained,
      );

      uniqueNegativeMethods.forEach(function (method) {
        uniquePositiveMethods.delete(method);
      });

      uniquePositiveMethods.forEach(function (method) {
        if (
          self.getMethod(method).applicableCountries &&
          self.getMethod(method).applicableCountries.length > 0 &&
          !self.getMethod(method).applicableCountries.includes(self.country)
        ) {
          uniquePositiveMethods.delete(method);
        }
      });

      const finalMethodsWithWithoutDuplicates = new Set([
        ...uniquePositiveMethods,
        ...positiveMethodsThatShouldBeRetained,
      ]);

      // Remove duplicates from final methods

      this.finalMethods = [...finalMethodsWithWithoutDuplicates];

      console.log("finalMethods", this.finalMethods);
      // console.log("finalMethods", finalMethods);
    }
  },
  computed: {
    ...mapGetters([
      "country",
      "QuizPageOneChoice",
      "QuizPageTwoChoice",
      "QuizPageThreeChoice",
    ]),
  },
  methods: {
    getMethod(id) {
      return this.allMethods.find((method) => method.id === id);
    },
    goToMethodDetailsPage(choiceId) {
      console.log(choiceId);
      // get the choice text
      const choiceText = this.getMethod(choiceId).name;
      if (choiceId != null) {
        this.$gtag.event("Navigation to Details Page", {
          event_category: "navigation_to_details_page",
          event_label: "navigation_to_details_page_from_quiz_results",
          value: choiceText,
        });
        this.$router.push({
          name: "Method Details",
          params: { choice: choiceId },
        });
      } else {
        alert("You must select a choice to proceed.");
      }
    },
    goQuizPageOne() {
      this.$router.push({
        name: "QuizPageOne",
      });
    },
    navigateToServiceFinder() {
      this.$gtag.event("Navigation to Service Finder", {
        event_category: "navigation_to_service_finder",
        event_label: "navigation_to_service_finder_from_quiz_results_page",
        value: "quiz_results_page",
      });
      window.open(
        "https://witsrhi-servicefinder-client.onrender.com/",
        "_blank",
      );
    },
    navigateToWhatsapp() {
      this.$gtag.event("Navigation to Talk to Somebody", {
        event_category: "navigation_to_talk_to_somebody",
        event_label: "navigation_to_talk_to_somebody_from_quiz_results_page",
        value: "quiz_results_page",
      });
      window.open("https://api.whatsapp.com/send?phone=27658698031", "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.plusicon {
  top: 12px;
  font-size: 2.3rem !important;
  font-weight: 900 !important;
}
.name-text {
  font-size: 0.7rem !important;
}
</style>
