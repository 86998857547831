<template>
  <v-container>
    <header-bar></header-bar>
    <v-row
      class="mt-4"
      v-for="(item, choiceIndex) in cards"
      :key="choiceIndex"
      @click="goToMethodDetailsPage(item.id)"
      no-gutters
    >
      <v-col cols="4">
        <v-card class="mr-4">
          <div
            class="d-flex flex-column text-center justify-center align-center"
          >
            <v-img
              v-if="item.image"
              contain
              :aspect-ratio="1"
              width="75"
              :src="item.image"
            >
            </v-img>
            <v-img
              v-else
              contain
              :aspect-ratio="1"
              width="75"
              src="@/assets/icons/none.png"
            >
            </v-img>
            <div class="mt-2 font-weight-black text-subtitle-2">
              {{ item.name }}
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="8">
        <div v-html="item.text"></div>
        <a
          style="text-decoration: underline !important"
          class="text-customfuschia"
        >
          Read more</a
        >
      </v-col>
      <v-divider class="my-4" color="customgrey"></v-divider>
    </v-row>
    <v-row class="mt-2">
      <v-col class="d-flex flex-row align-center justify-space-around">
        <v-btn
          class="text-white rounded-pill"
          size="large"
          @click="goHome()"
          color="customfuschia"
        >
          BACK
        </v-btn>
        <v-btn
          class="text-white rounded-pill"
          size="large"
          color="customfuschia"
          @click="navigateToServiceFinder"
        >
          FIND A CLINIC
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MethodDataService from "../services/MethodDataService";
import HeaderBar from "../components/HeaderBar.vue";
export default {
  name: "PreventativeMethodsVertical",
  components: { HeaderBar },
  data: () => ({
    cards: [],
    allMethods: [],
  }),
  async created() {
    this.allMethods = await MethodDataService.getAll();
    this.cards = this.allMethods.map((method) => {
      if (!method.removeFromDetailsPage) {
        return {
          id: method.id,
          name: method.name,
          image: method.imageUrl,
          text: method.blurb,
          position: method.position,
        };
      }
    });
    this.cards = this.cards.filter((card) => card != undefined);
    this.cards.sort((a, b) => (a.position < b.position ? -1 : 1));
    console.log(this.cards);
  },
  methods: {
    goHome() {
      this.$router.push({
        name: "HomePage",
      });
    },
    goToMethodDetailsPage(choiceId) {
      console.log(choiceId);
      if (choiceId != null) {
        this.$router.push({
          name: "Method Details",
          params: { choice: choiceId },
        });
      } else {
        alert("You must select a choice to proceed.");
      }
    },
    navigateToServiceFinder() {
      this.$gtag.event("Navigation to Service Finder", {
        event_category: "navigation_to_service_finder",
        event_label: "navigation_to_service_finder_from_methods_list_page",
        value: "methods_list_page",
      });
      window.open(
        "https://witsrhi-servicefinder-client.onrender.com/",
        "_blank",
      );
    },
  },
};
</script>

<style></style>
