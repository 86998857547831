<template>
  <v-card>
    <v-row>
      <v-col sm="12" lg="4">
        <v-card-title>Login</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="email"
              :rules="[(v) => !!v || 'Name is required']"
              label="Email Address"
              required
              type="email"
            ></v-text-field>

            <v-text-field
              v-model="password"
              :rules="[(v) => !!v || 'Password is required']"
              label="Password"
              required
            ></v-text-field>

            <v-btn color="success" class="mr-4" @click="login"> Login </v-btn>
          </v-form>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "LoginAdmin",
  data() {
    return {
      valid: true,
      email: "",
      password: "",
    };
  },
  async created() {},
  methods: {
    ...mapActions(["logIn", "logOut", "fetchUser"]),
    async login() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        try {
          await this.logIn({ email: this.email, password: this.password });
          this.$swal("Logged In Successfully");
        } catch (error) {
          this.$swal("Login failed", "", "error");
        }
      }
    },
  },
};
</script>

<style scoped></style>
