<template>
  <v-card>
    <v-row>
      <v-col sm="12" lg="4">
        <v-card-title>{{
          editing && editingItem
            ? `Editing ${editingItem.text}`
            : "Create Question"
        }}</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="text"
              :counter="10"
              :rules="[(v) => !!v || 'Text is required']"
              label="Text"
              required
            ></v-text-field>

            <v-select
              v-model="positiveMethods"
              :items="allMethods"
              item-title="name"
              item-value="id"
              :rules="[(v) => !!v || 'Positive Methods is required']"
              label="Positive Methods"
              required
              multiple
            ></v-select>

            <v-select
              v-model="negativeMethods"
              :items="allMethods"
              item-title="name"
              item-value="id"
              :rules="[(v) => !!v || 'Negative Methods is required']"
              label="Negative Methods"
              required
              multiple
            ></v-select>

            <v-text-field
              v-model.number="position"
              :rules="[
                (v) =>
                  Number.isInteger(Number(v)) ||
                  'The value must be an integer number',
              ]"
              label="Position"
              required
            ></v-text-field>

            <v-btn color="info" class="mr-4" @click="validate">
              Validate
            </v-btn>

            <v-btn color="error" class="mr-4" @click="reset">
              Reset Form
            </v-btn>

            <v-btn color="success" class="mr-4" @click="save"> Save </v-btn>
          </v-form>
        </v-card-text>
      </v-col>
      <v-col v-if="allQuestions && allQuestions.length > 0" sm="12" lg="8">
        <v-card-title>Questions</v-card-title>
        <v-card-text>
          <EasyDataTable
            :headers="headers"
            :items="allQuestions"
            :sort-by="'position'"
          >
            <template #item-positiveMethods="item">
              <span v-if="allMethods.length > 0">
                <v-chip
                  v-for="(methodId, index) in item.positiveMethods"
                  :key="index"
                  class="ma-2"
                  size="small"
                  color="success"
                >
                  {{ findMethodNameById(methodId) }}
                </v-chip>
              </span>
            </template>
            <template #item-negativeMethods="item">
              <span v-if="allMethods.length > 0">
                <v-chip
                  v-for="(methodId, index) in item.negativeMethods"
                  :key="index"
                  class="ma-2"
                  size="small"
                  color="error"
                >
                  {{ findMethodNameById(methodId) }}
                </v-chip>
              </span>
            </template>
            <template #item-operation="item">
              <div>
                <v-icon
                  color="info"
                  icon="mdi-pencil"
                  @click="editItem(item)"
                ></v-icon>
                <v-icon
                  color="error"
                  icon="mdi-delete"
                  @click="deleteItem(item)"
                ></v-icon>
              </div>
            </template>
          </EasyDataTable>
          <v-btn color="info" class="mr-4 my-2" @click="cleanQuestions()">
            Clean questions after removing method
          </v-btn>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import QuestionDataService from "../services/QuestionDataService";
import MethodDataService from "../services/MethodDataService";

export default {
  name: "QuestionAdmin",
  data() {
    return {
      valid: true,
      editing: false,
      editingItem: "",
      allQuestions: [],
      allMethods: [],
      headers: [
        { text: "Text", value: "text" },
        { text: "Positive Methods", value: "positiveMethods" },
        { text: "Negative Methods", value: "negativeMethods" },
        { text: "Position", value: "position", sortable: false },
        { text: "Operation", value: "operation" },
      ],
      text: "",
      positiveMethods: [],
      negativeMethods: [],
      position: 0,
    };
  },
  async created() {
    this.allQuestions = await QuestionDataService.getAll();
    this.allMethods = await MethodDataService.getAll();
  },
  methods: {
    async validate() {
      const { valid } = await this.$refs.form.validate();
      if (valid) alert("Form is valid");
    },
    reset() {
      this.editing = false;
      this.editingItem = "";
      this.text = "";
      this.positiveMethods = [];
      this.negativeMethods = [];
      this.position = 0;
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async save() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        const question = {
          text: this.text,
          positiveMethods: this.positiveMethods,
          negativeMethods: this.negativeMethods,
          position: this.position,
        };
        if (this.editing && this.editingItem && this.editingItem.id) {
          var updateResponse = await QuestionDataService.update(
            this.editingItem.id,
            question,
          );
          if (updateResponse) {
            this.$swal("Question updated successfully");
          } else {
            this.$swal("Error updating question", "", "error");
          }
        } else {
          var createResponse = await QuestionDataService.create(question);
          console.log("createResponse:", createResponse);
          if (createResponse) {
            this.$swal("Question created successfully");
          }
        }
        this.reset();
        this.allQuestions = await QuestionDataService.getAll();
      }
    },
    editItem(item) {
      this.editing = true;
      this.editingItem = item;
      this.text = item.text || "";
      this.positiveMethods = item.positiveMethods || [];
      this.negativeMethods = item.negativeMethods || [];
      this.position = item.position || 0;
    },
    async deleteItem(item) {
      console.log(item);
      this.$swal({
        title: "Are you sure you want to delete this item?",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var deleteResponse = await QuestionDataService.delete(item.id);
          if (deleteResponse) {
            this.$swal("Question deleted successfully");
            this.allQuestions = await QuestionDataService.getAll();
          } else {
            this.$swal("Error deleting question", "", "error");
          }
        } else if (result.isDismissed) {
          this.$swal("Cancelled", "", "info");
        }
      });
    },
    findMethodNameById(id) {
      const method = this.allMethods.find((method) => method.id === id);
      return method.name || "";
    },
    cleanQuestions() {
      this.allQuestions.forEach(async (question) => {
        var { id, text, positiveMethods, negativeMethods, position } = question;

        positiveMethods.forEach(async (methodId) => {
          const method = this.allMethods.find(
            (method) => method.id === methodId,
          );
          if (!method) {
            console.log("method not found:", methodId);
            // remove method if not found from question
            const index = positiveMethods.indexOf(methodId);
            console.log(index);
            if (index > -1) {
              positiveMethods.splice(index, 1);
            }
          }
        });

        negativeMethods.forEach(async (methodId) => {
          const method = this.allMethods.find(
            (method) => method.id === methodId,
          );
          if (!method) {
            console.log("method not found:", methodId);
            // remove method if not found from question
            const index = negativeMethods.indexOf(methodId);
            console.log(index);
            if (index > -1) {
              negativeMethods.splice(index, 1);
            }
          }
        });

        const questionToSave = {
          text,
          positiveMethods,
          negativeMethods,
          position,
        };
        var updateResponse = await QuestionDataService.update(
          id,
          questionToSave,
        );
        if (updateResponse) {
          console.log("Question updated successfully:", id);
        } else {
          console.log("Error updating question:", id);
        }
      });
    },
  },
};
</script>

<style scoped></style>
