<template>
  <div class="mb-2">
    <v-app-bar class="d-flex flex-row flex-start align-center">
      <v-app-bar-nav-icon
        class="font-weight-black"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <span class="text-body-1 font-weight-black text-black text-uppercase ml-1"
        >Your HIV Prevention Journey</span
      >
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" temporary color="customfuschia">
      <v-list class="mx-n2">
        <v-list-item-group v-model="group">
          <router-link to="/QuizPageOne">
            <v-list-item>
              <div
                :class="[
                  'menu-text  text-uppercase font-weight-light',
                  $route.path != '/QuizPageOne' &&
                  $route.path != '/QuizPageTwo' &&
                  $route.path != '/QuizPageThree'
                    ? 'text-white'
                    : 'menu-text',
                ]"
              >
                Take the quiz
              </div>
            </v-list-item>
          </router-link>
          <v-divider color="customgrey"></v-divider>
          <router-link to="/PreventativeMethodsVertical">
            <v-list-item>
              <div
                :class="[
                  'menu-text  text-uppercase font-weight-light',
                  $route.path != '/PreventativeMethodsVertical'
                    ? 'text-white'
                    : 'menu-text',
                ]"
              >
                HIV prevention methods
              </div>
            </v-list-item>
          </router-link>
          <v-divider color="customgrey"></v-divider>
          <router-link to="/STIManagement">
            <v-list-item>
              <div
                :class="[
                  'menu-text  text-uppercase font-weight-light',
                  $route.path != '/STIManagement' ? 'text-white' : 'menu-text',
                ]"
              >
                STI Management
              </div>
            </v-list-item>
          </router-link>
          <v-divider color="customgrey"></v-divider>
          <v-list-item @click="navigateToServiceFinder">
            <div class="menu-text text-white text-uppercase font-weight-light">
              Find a Clinic
            </div>
          </v-list-item>
          <v-divider color="customgrey"></v-divider>
          <router-link to="/">
            <v-list-item>
              <div
                :class="[
                  'menu-text  text-uppercase font-weight-light',
                  $route.path != '/' ? 'text-white' : 'menu-text',
                ]"
              >
                Home
              </div>
            </v-list-item>
          </router-link>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "HeaderBar",
  data: () => ({
    drawer: false,
    group: null,
  }),
  methods: {
    navigateToServiceFinder() {
      this.$gtag.event("Navigation to Service Finder", {
        event_category: "navigation_to_service_finder",
        event_label: "navigation_to_service_finder_from_header_bar",
        value: "header_bar",
      });
      window.open(
        "https://witsrhi-servicefinder-client.onrender.com/",
        "_blank",
      );
    },
  },
};
</script>
<style>
.menu-text {
  font-family: "Montserrat", sans-serif;
  color: #e3d80d;
}
</style>
