import {
  db,
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  collection,
  doc,
  getDocs,
  getDoc,
  addDoc,
  deleteDoc,
  setDoc,
} from "../firebaseConfig.js";

const questionsCollectionRef = collection(db, "questions");

class QuestionDataService {
  async getAll() {
    const docsSnapshot = await getDocs(questionsCollectionRef);
    return docsSnapshot.docs.map((docRef) => {
      return { ...docRef.data(), id: docRef.id };
    });
  }

  async get(id) {
    var docRef = doc(db, "questions", id);
    var docSnapshot = await getDoc(docRef);
    return { ...docSnapshot.data(), id: docSnapshot.id };
  }

  async create(question) {
    var docRef = await addDoc(questionsCollectionRef, question);
    return { ...question, id: docRef.id };
  }

  async update(id, value) {
    try {
      console.log("value", value);
      await setDoc(doc(db, "questions", id), value, { merge: true });
      return true;
    } catch (error) {
      console.log("Error updating document: ", error);
      return false;
    }
  }

  async delete(id) {
    try {
      await deleteDoc(doc(db, "questions", id));
      return true;
    } catch (error) {
      console.log("Error deleting document: ", error);
      return false;
    }
  }

  async uploadImage(imageFile) {
    var storageRef = ref(getStorage(), "questions/" + imageFile.name);
    const metadata = {
      contentType: imageFile.type,
    };
    await uploadBytes(storageRef, imageFile, metadata);
    return await getDownloadURL(storageRef);
  }
}

export default new QuestionDataService();
