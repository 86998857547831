import { createRouter, createWebHashHistory } from "vue-router";
import HomePage from "../views/HomePage.vue";
import QuizPageOne from "../views/QuizPageOne.vue";
import QuizPageTwo from "../views/QuizPageTwo.vue";
import QuizPageThree from "../views/QuizPageThree.vue";
import QuizResultsPage from "../views/QuizResultsPage.vue";
import PreventativeMethodsVertical from "../views/PreventativeMethodsVertical.vue";
import STIManagement from "../views/STIManagement.vue";
import MethodDetails from "../views/MethodDetails.vue";
import Admin from "../views/Admin.vue";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/QuizPageOne",
    name: "QuizPageOne",
    component: QuizPageOne,
  },
  {
    path: "/QuizPageTwo",
    name: "QuizPageTwo",
    component: QuizPageTwo,
  },
  {
    path: "/QuizPageThree",
    name: "QuizPageThree",
    component: QuizPageThree,
  },
  {
    path: "/QuizResultsPage",
    name: "QuizResultsPage",
    component: QuizResultsPage,
  },
  {
    path: "/PreventativeMethodsVertical",
    name: "PreventativeMethodsVertical",
    component: PreventativeMethodsVertical,
  },
  {
    path: "/STIManagement",
    name: "STIManagement",
    component: STIManagement,
  },
  {
    path: "/MethodDetails/:choice",
    name: "Method Details",
    component: MethodDetails,
    props: true,
  },
  {
    path: "/Admin",
    name: "Admin",
    component: Admin,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
