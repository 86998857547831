<template>
  <v-container>
    <header-bar></header-bar>

    <v-row v-if="activeMethod" class="mb-2 mx-1">
      <v-col cols="12">
        <div
          class="text-h5 font-weight-bold"
          :style="{ color: activeMethodColor }"
        >
          {{ activeMethod.name }}
        </div>
      </v-col>
      <v-col cols="12">
        <v-card class="d-flex align-left">
          <div class="d-flex flex-column align-center">
            <v-img
              contain
              :aspect-ratio="1"
              width="75"
              :src="activeMethod.imageUrl"
            >
            </v-img>
            <div class="mt-2 font-weight-black text-subtitle-2">
              {{ activeMethod.name }}
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="activeMethod" class="mb-2 mx-1">
      <v-col cols="12">
        <v-expansion-panels v-if="activeMethod">
          <v-expansion-panel
            class="mb-2"
            elevation="0"
            :style="{ border: `2px solid ${activeMethodColor}` }"
          >
            <v-expansion-panel-title>
              <template v-slot:default="{ expanded }">
                <v-fade-transition leave-absolute>
                  <div
                    class="font-weight-bold"
                    :style="{ color: expanded ? activeMethodColor : '#000000' }"
                  >
                    What is it?
                  </div>
                </v-fade-transition>
              </template>
              <template v-slot:actions="{ expanded }">
                <v-icon
                  :color="expanded ? '#000000' : activeMethodColor"
                  :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                ></v-icon>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div v-html="activeMethod.whatIsIt"></div>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel
            class="mb-2"
            elevation="0"
            :style="{ border: `2px solid ${activeMethodColor}` }"
          >
            <v-expansion-panel-title>
              <template v-slot:default="{ expanded }">
                <v-fade-transition leave-absolute>
                  <div
                    class="font-weight-bold"
                    :style="{ color: expanded ? activeMethodColor : '#000000' }"
                  >
                    How does it work?
                  </div>
                </v-fade-transition>
              </template>
              <template v-slot:actions="{ expanded }">
                <v-icon
                  :color="expanded ? '#000000' : activeMethodColor"
                  :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                ></v-icon>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div v-html="activeMethod.howDoesItWork"></div>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel
            class="mb-2"
            elevation="0"
            :style="{ border: `2px solid ${activeMethodColor}` }"
          >
            <v-expansion-panel-title>
              <template v-slot:default="{ expanded }">
                <v-fade-transition leave-absolute>
                  <div
                    class="font-weight-bold"
                    :style="{ color: expanded ? activeMethodColor : '#000000' }"
                  >
                    How is it used?
                  </div>
                </v-fade-transition>
              </template>
              <template v-slot:actions="{ expanded }">
                <v-icon
                  :color="expanded ? '#000000' : activeMethodColor"
                  :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                ></v-icon>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div v-html="activeMethod.howIsItUsed"></div>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel
            class="mb-2"
            elevation="0"
            :style="{ border: `2px solid ${activeMethodColor}` }"
          >
            <v-expansion-panel-title>
              <template v-slot:default="{ expanded }">
                <v-fade-transition leave-absolute>
                  <div
                    class="font-weight-bold"
                    :style="{ color: expanded ? activeMethodColor : '#000000' }"
                  >
                    How well does it work?
                  </div>
                </v-fade-transition>
              </template>
              <template v-slot:actions="{ expanded }">
                <v-icon
                  :color="expanded ? '#000000' : activeMethodColor"
                  :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                ></v-icon>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div v-html="activeMethod.howWellDoesItWork"></div>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel
            class="mb-2"
            elevation="0"
            :style="{ border: `2px solid ${activeMethodColor}` }"
          >
            <v-expansion-panel-title>
              <template v-slot:default="{ expanded }">
                <v-fade-transition leave-absolute>
                  <div
                    class="font-weight-bold"
                    :style="{ color: expanded ? activeMethodColor : '#000000' }"
                  >
                    What are the side effects?
                  </div>
                </v-fade-transition>
              </template>
              <template v-slot:actions="{ expanded }">
                <v-icon
                  :color="expanded ? '#000000' : activeMethodColor"
                  :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                ></v-icon>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div v-html="activeMethod.whatAreTheSideEffect"></div>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel
            class="mb-2"
            elevation="0"
            :style="{ border: `2px solid ${activeMethodColor}` }"
          >
            <v-expansion-panel-title>
              <template v-slot:default="{ expanded }">
                <v-fade-transition leave-absolute>
                  <div
                    class="font-weight-bold"
                    :style="{ color: expanded ? activeMethodColor : '#000000' }"
                  >
                    Some considerations when choosing this method.
                  </div>
                </v-fade-transition>
              </template>
              <template v-slot:actions="{ expanded }">
                <v-icon
                  :color="expanded ? '#000000' : activeMethodColor"
                  :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                ></v-icon>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <ul id="considerations" class="pl-4">
                <li
                  v-for="(item, index) in activeMethod.considerations"
                  :key="index"
                  class="mb-2"
                >
                  <div v-html="item.text"></div>
                </li>
              </ul>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <v-expansion-panel
            class="mb-2"
            elevation="0"
            :style="{ border: `2px solid ${activeMethodColor}` }"
          >
            <v-expansion-panel-title>
              <template v-slot:default="{ expanded }">
                <v-fade-transition leave-absolute>
                  <div
                    class="font-weight-bold"
                    :style="{ color: expanded ? activeMethodColor : '#000000' }"
                  >
                    Frequently Asked Questions.
                  </div>
                </v-fade-transition>
              </template>
              <template v-slot:actions="{ expanded }">
                <v-icon
                  :color="expanded ? '#000000' : activeMethodColor"
                  :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                ></v-icon>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text style="width: 100%" class="faqpanel">
              <div
                v-for="(item, index) in activeMethod.faqs"
                :key="index"
                :class="{ even: index % 2, odd: !(index % 2) }"
              >
                <div class="px-5 pt-5">
                  <span
                    class="font-weight-bold"
                    :style="{ color: activeMethodColor }"
                    >Q: </span
                  ><span class="font-weight-bold">{{ item.question }}</span>
                </div>
                <div class="px-5 pb-5">
                  <span
                    class="font-weight-bold"
                    :style="{ color: activeMethodColor }"
                    >A: </span
                  ><span v-html="item.answer"></span>
                </div>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col class="d-flex flex-row align-center justify-space-around">
        <v-btn
          class="text-white rounded-pill"
          size="large"
          @click="$router.go(-1)"
          color="customfuschia"
        >
          BACK
        </v-btn>
        <v-btn
          class="text-white rounded-pill"
          size="large"
          color="customfuschia"
          @click="navigateToServiceFinder"
        >
          FIND A CLINIC
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MethodDataService from "../services/MethodDataService";
import HeaderBar from "../components/HeaderBar.vue";
export default {
  name: "MethodDetails",
  components: { HeaderBar },
  props: ["choice"],
  data: () => ({
    activeMethod: null,
    panel: [0, 1],
    disabled: false,
    readonly: false,
  }),
  async created() {
    this.activeMethod = await MethodDataService.get(this.choice);
    console.log(this.activeMethod);
  },
  computed: {
    activeMethodColor() {
      return this.activeMethod && this.activeMethod.color
        ? this.activeMethod.color
        : "#000000";
    },
  },
  methods: {
    navigateToServiceFinder() {
      this.$gtag.event("Navigation to Service Finder", {
        event_category: "navigation_to_service_finder",
        event_label: "navigation_to_service_finder_from_method_details_page",
        value: "method_details_page",
      });
      window.open(
        "https://witsrhi-servicefinder-client.onrender.com/",
        "_blank",
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.v-expansion-panel-title__icon, .v-expansion-panel-title
  color: black !important
// table, th, td
//   border:1px solid black
.faqpanel:deep(.v-expansion-panel-text__wrapper)
  padding: 0 0 0 0 !important

.even
  background: #ffffff
.odd
  background: #efede4
</style>
