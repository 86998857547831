<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
html,
body,
v-main {
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}

.custom-orange {
  color: #f37121;
}

.custom-pink {
  color: #f491af;
}

.custom-yellow {
  color: #e3d80d;
}

.custom-light-blue {
  color: #0085c4;
}

.custom-green {
  color: #44b651;
}

.custom-dark-blue {
  color: #0057a7;
}

.custom-fuschia {
  color: #b43688 !important;
}

.custom-poppy {
  color: #e24149;
}

.custom-grey {
  color: #efede4;
}

.custom-background-color {
  background-color: #efede4 !important;
}

a {
  text-decoration: none !important;
}

.v-btn__content {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.line {
  width: 30px;
  height: 20px;
  margin-bottom: 15px;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 2px solid #efede4;
}

// .v-divider {
//   display: block;
//   flex: 1 1 0px;
//   max-width: 100%;
//   height: 2px;
//   max-height: 0px;
//   border: solid;
//   border-width: thin 0 0 0;
//   transition: inherit;
// }
</style>
